import React from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../../../components/shadch/ui/ui/dialog";
import { Button } from "../../../components/shadch/ui/ui/button";

const InsurerDeleteModal = ({ isOpen, onClose, roleName, onDelete }) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-sm" hideCloseButton={true}>
        <DialogHeader>
          <DialogTitle>Delete insurer</DialogTitle>
        </DialogHeader>
        <div className="p-4">
          <p className="mb-2">Insurer Name</p>
          <div className="border p-2 rounded-lg">{roleName}</div>
        </div>
        <div className="flex justify-end gap-8 mt-6">
          <Button className="border px-6 " onClick={onClose}>
            Close
          </Button>
          <Button className="text-white bg-red-500 px-6"  onClick={onDelete}>
            Delete
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default InsurerDeleteModal;
