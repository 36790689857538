import { Separator } from "../../../components/shadch/ui/ui/separator";
import React, { useState, useEffect } from "react";
import editIcon from "../../../components/icons/editIconr.svg";
import deleteIcon from "../../../components/icons/deleteIcon.svg";
import { Button } from "../../../components/shadch/ui/ui/button";
import ToggleSwitch from "../../../components/ToggleSwitch";
import apiClient from "../../../http/common";
import { toast } from "react-toastify";
import RoleDeleteModal from "./RoleDeleteModal";
import RoleModal from "./RoleModal";
import { PropagateLoader } from "react-spinners"; // Import the loader

const RolesAndPermissions = () => {
  const [roles, setRoles] = useState([]); // To store role data with permissions
  const [selectedRole, setSelectedRole] = useState(null); // To track the selected role
  const [isLoading, setIsLoading] = useState(false);
  const [mutation, setMutation] = useState({ isLoading: false });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [roleToDelete, setRoleToDelete] = useState(null);
  const [isRoleModalOpen, setIsRoleModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  // Fetch roles on component mount (including permissions)
  const fetchAdminRoles = async () => {
    try {
      setIsLoading(true);
      const response = await apiClient.get("/admin/api/AdminAPI/GetAdminRoles");
      setRoles(response?.data?.data);
    } catch (error) {
      console.error(error?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const openRoleModal = (role = null) => {
    setSelectedRole(role);
    setIsEditMode(!!role);
    setIsRoleModalOpen(true);
  };

  const handleDeleteRole = async () => {
    try {
      await apiClient.delete(
        `/admin/api/AdminAPI/DeleteAdminRole?RoleId=${selectedRole.id}`
      );
      toast.success("Role deleted successfully");
      setRoles(roles.filter((r) => r.id !== selectedRole.id));
    } catch (error) {
      toast.error("Failed to delete role");
    } finally {
      setIsDeleteModalOpen(false);
      fetchAdminRoles();
    }
  };

  const rolesRepresentation = {
    canApproveLmd: "Allow to approve Lmd",
    canAssignPermission: "Allow to assign permission",
    canDisapproveLmd: "Allow to disapprove Lmd",
    canCreateFacility: "Add to create facility",
    canApproveFacility: "Allow to approve facility",
    canDisapproveFacility: "Allow to disable facility",
    canCreatePartner: "Allow to create partner",
    canApprovePartner: "Allow to approve partner",
    canDisapprovePartner: "Allow to disable partner",
    canReactivateDeactivate: "Allow to re-activate deactivated",
    canUpdateManualClaimStatus: "Allow to update manual claim status",
    canVetClaimAndProvideComments: "Allow to vet claims and provide comments",
    canMonitorPayments: "Allow to monitor payments",
    // Add any other representations you need
  };

  const handleRoleSelect = (role) => {
    const updatedRole = {
      ...role,
      permissions: role.permissions || [],
      notPermissions: role.notPermissions || [],
    };
    setSelectedRole(updatedRole);
  };

  const handleToggle = (perm, isPermission) => {
    const updatedRole = { ...selectedRole };
    const targetArray = isPermission ? "permissions" : "notPermissions";

    if (updatedRole[targetArray].includes(perm)) {
      updatedRole[targetArray] = updatedRole[targetArray].filter(
        (p) => p !== perm
      );
    } else {
      updatedRole[targetArray].push(perm);
    }

    setSelectedRole(updatedRole);
  };

  const onEditRole = () => {
    const data = {
      id: selectedRole.id,
      roleName: selectedRole.roleName,
      permissions: selectedRole.permissions,
    };

    setMutation({ isLoading: true });
    apiClient
      .put(`/admin/api/AdminAPI/EditRolePermissions`, data)
      .then((response) => {
        toast.success(response?.data?.message || "Role updated successfully");
        setMutation({ isLoading: false });
      })
      .catch((err) => {
        toast.error("Failed to update role");
        setMutation({ isLoading: false });
      });
  };

  useEffect(() => {
    fetchAdminRoles();
  }, []);

  return (
    <div className="p-8 bg-gray-50 min-h-screen">
      {isLoading ? (
        <div className="flex justify-center items-center h-64"> {/* Centered Loader */}
          <PropagateLoader/>
        </div>
      ) : (
        <>
          <div className="flex justify-between items-center">
            <h1 className="text-2xl font-semibold text-gray-700">
              Roles & Permissions
            </h1>
            <button
              onClick={() => openRoleModal()}
              className="bg-[#2CBCEF] text-white px-6 py-2 rounded shadow"
            >
              Add Role
            </button>
          </div>
          <div className="flex gap-6 mt-8">
            <div className="w-1/3 bg-white shadow-md rounded-lg p-4">
              <h2 className="text-[20px] font-semibold mb-4 text-[#2CBCEF]">
                Role Name
              </h2>
              <ul>
                {roles?.map((role, index) => (
                  <div key={role.id}>
                    <li
                      onClick={() => handleRoleSelect(role)}
                      className={`flex justify-between items-center py-3 px-4 hover:bg-gray-100 rounded cursor-pointer ${
                        selectedRole?.id === role.id ? "bg-blue-100" : ""
                      }`}
                    >
                      <span>{role.roleName}</span>
                      <div className="flex gap-2">
                        <button
                          onClick={() => openRoleModal(role)}
                          className="text-blue-500 hover:text-blue-700"
                        >
                          <img src={editIcon} alt="Edit Icon" />
                        </button>
                        <button
                          onClick={() => {
                            setSelectedRole(role);
                            setIsDeleteModalOpen(true);
                          }}
                          className="text-red-500 hover:text-red-700"
                        >
                          <img src={deleteIcon} alt="Delete Icon" />
                        </button>
                      </div>
                    </li>
                    {index < roles.length - 1 && (
                      <Separator orientation="horizontal" className="my-2" />
                    )}
                  </div>
                ))}
              </ul>
            </div>

            {selectedRole && (
              <div className="w-2/3 bg-white shadow-md rounded-lg p-4">
                <div>
                  <div className="flex justify-between">
                    <h1 className="text-[#2CBCEF] font-[700] mb-2 tracking-wider">
                      PERMISSIONS
                    </h1>
                    <h1 className="text-[#2CBCEF] font-[700] mb-2 tracking-wider">
                      ACTIONS
                    </h1>
                  </div>
                  {selectedRole?.permissions.map((perm, index) => (
                    <div
                      key={index}
                      className="w-full flex justify-between gap-2"
                    >
                      <div className="text-[#4A4A4A] w-4/5 text-[15px] font-[500] my-4">
                        {rolesRepresentation[perm] || perm}
                      </div>
                      <ToggleSwitch
                        onChange={() => handleToggle(perm, true)}
                        checked={selectedRole.permissions.includes(perm)}
                      />
                    </div>
                  ))}
                  {selectedRole?.notPermissions.map((perm, index) => (
                    <div
                      key={index}
                      className="w-full flex justify-between gap-2"
                    >
                      <div className="text-[#808080] w-4/5 text-[15px] font-[500] my-4">
                        {rolesRepresentation[perm] || perm}
                      </div>
                      <ToggleSwitch
                        onChange={() => handleToggle(perm, false)}
                        checked={false}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          <RoleModal
            isOpen={isRoleModalOpen}
            onClose={() => {
              setIsRoleModalOpen(false);
              window.location.reload();
            }}
            isEditMode={isEditMode}
            role={selectedRole}
            onSuccess={() => {
              setIsRoleModalOpen(false);
              window.location.reload();
            }}
            rolesRepresentation={rolesRepresentation}
          />
          {selectedRole && (
            <RoleDeleteModal
              isOpen={isDeleteModalOpen}
              onClose={() => {
                setIsDeleteModalOpen(false);
                window.location.reload();
              }}
              roleName={selectedRole.roleName}
              onDelete={() => {
                handleDeleteRole();
                window.location.reload();
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default RolesAndPermissions;
