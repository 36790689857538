import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    user: {},
    balance: null, // This is the wallet balance state
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setToken: (state, action) => {
      state.user = { ...state.user, token: action.payload };
    },
    clearUser: (state) => {
      state.user = {};
      state.balance = null; // Clear the wallet balance when the user is cleared
    },
    setWalletBalance: (state, action) => {
      state.balance = action.payload; // Update the wallet balance
    },
  },
});

export const { setUser, setToken, clearUser, setWalletBalance } = userSlice.actions;

export default userSlice.reducer;
