// @mui
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
// sections
import { DoctorPageTable } from "../../sections/@dashboard/doctor";
import {
  ACTIVE_TABLE_HEAD,
  APPROVED_TABLE_HEAD,
  UNAPPROVED_TABLE_HEAD,
  REJECTED_TABLE_HEAD,
  DEACTIVE_TABLE_HEAD,
  SCORE_TABLE_HEAD,
} from "../../sections/@dashboard/doctor/DoctorHelperFunctions";
import useDoctor from "../../sections/@dashboard/doctor/doctorHook";
import ErrorModal from "../../components/Modal/ErrorModal";
import { useContext } from "react";
import { AuthContext } from "../../context/auth-context";
import LoadingBackdrop, { NewLoadingBackdrop } from "../../components/loader";
import TransitionsSnackbar from "../../components/snackBar";
import { a11yProps, TabPanel } from "../../utils/helper";
import Empty from "../../components/commonElements/empty/empty";

export default function DoctorPage() {
  const auth = useContext(AuthContext);
  const {
    approvedDoctors,
    loadedRejectedDoctors,
    refetchLoadedRejectedDoctors,
    loadedUnApprovedDoctors,
    activeDoctors,
    isLoading,
    error,
    clearError,
    unApprovedLoading,
    unApprovedError,
    unApprovedIsError,

    rejectedLoading,
    rejectedError,
    rejectedIsError,

    approvedLoading,
    approvedError,
    approvedIsError,
    activeLoading,
    activeError,
    activeIsError,
    open,
    page,
    order,
    selected,
    orderBy,
    filterName,
    filterRole,
    rowsPerPage,
    tabsValue,
    handleTabsValueChange,
    handleOpenMenu,
    handleCloseMenu,
    handleSelectAllClick,
    approvedIsNotFound,
    unApprovedIsNotFound,
    rejectedIsNotFound,
    activeIsNotFound,
    emptyRowsApproved,
    emptyRowsRejected,
    emptyRowsUnApproved,
    emptyRowsActive,
    filteredApproved,
    filteredRejected,
    filteredUnApproved,
    filteredActive,
    handleFilterByRole,
    handleFilterByName,
    handleChangeRowsPerPage,
    handleChangePage,
    handleClick,
    handleRequestSort,
    approvalRequestHandler,
    rejectRequestHandler,
    deactivateRequestHandler,
    success,
    handleSnackbarClose,
    specializations,
    ApproveLmdLoading,
    rejectLmdLoading,
    deactivateLmdMutationError,
    deactivateLmdLoading,

    deactivatedDoctors,
    deactiveError,
    deActiveIsNotFound,
    deactiveIsError,
    deactiveLoading,
    emptyRowsDeactive,
    filteredUnActive,
    reactivateRequestHandler,
    text,
    setText,

    scoreAppDoctors,
    scoreAppLoading,
    scoreAppError,
    scoreAppIsError,
    scoreAppIsNotFound,
    emptyRowsScoreApp,
    filteredScoreApp,
    ScoreDoctorLoading,
    AddScoreAppHandler,
    removeScoreDoctorLoading,
    removeScoreDoctorHandler,
  } = useDoctor(auth.token);

  return (
    <>
      <ErrorModal error={error} onClear={clearError} />
      {/* <DeactivateModal
        open={openDeleteModal}
        onClose={handleClose}
      /> */}
      <TransitionsSnackbar
        open={success.value}
        message={success.message}
        onClose={handleSnackbarClose}
      />
      {/* <LoadingBackdrop
        open={isLoading}
        // open={true}
        styles={{
          marginTop: '12rem',
          mx: '1.5rem',
          borderRadius: '0.75rem',
        }}
      > */}
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          // mb={xs: 15, lg: 5}
          // mb={3}
          mt={2}
          sx={
            {
              // backgroundColor: 'pink'
            }
          }
        >
          {/* <Typography variant="h4" gutterBottom>
            Doctor(LMD)
          </Typography> */}
          {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            New Doctor
          </Button> */}
        </Stack>

        <Card>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tabsValue}
              onChange={handleTabsValueChange}
              aria-label="basic tabs example"
            >
              <Tab label="Approved" {...a11yProps(0)} />
              <Tab label="Unapproved" {...a11yProps(1)} />
              {/* <Tab label="Active" {...a11yProps(2)} /> */}
              <Tab label="Deactivated" {...a11yProps(2)} />
              <Tab label="Rejected" {...a11yProps(3)} />
              <Tab label="Score App" {...a11yProps(4)} />
            </Tabs>
          </Box>
          <TabPanel value={tabsValue} index={0}>
            {approvedDoctors?.length < 1 && (
              <Empty
                altText="doctors list is empty"
                title="No approved doctor found, please approve a doctor from the unapproved doctor list"
              />
            )}
            {approvedDoctors?.length > 0 && (
              <DoctorPageTable
                id="approved"
                selected={selected.approved}
                filterName={filterName.approved}
                handleFilterByName={handleFilterByName}
                filterRole={filterRole.approved}
                handleFilterByRole={handleFilterByRole}
                order={order.approved}
                orderBy={orderBy.approved}
                handleRequestSort={handleRequestSort}
                handleSelectAllClick={handleSelectAllClick}
                filteredUsers={filteredApproved}
                mainList={approvedDoctors}
                rowsPerPage={rowsPerPage.approved}
                handleClick={handleClick}
                handleOpenMenu={handleOpenMenu}
                emptyRows={emptyRowsApproved}
                isNotFound={approvedIsNotFound}
                handleChangePage={handleChangePage}
                deactivateRequestHandler={deactivateRequestHandler}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                page={page.approved}
                open={open.approved}
                handleCloseMenu={handleCloseMenu}
                isLoading={isLoading}
                TABLE_HEAD={APPROVED_TABLE_HEAD}
                tabsValue={tabsValue}
                specializations={specializations}
                deactivateLmdMutationError={deactivateLmdMutationError}
                deactivateLmdLoading={deactivateLmdLoading}
                text={text}
                setText={setText}
                AddScoreAppHandler={AddScoreAppHandler}
                ScoreDoctorLoading={ScoreDoctorLoading}
              />
            )}
            {approvedIsError ? (
              <div>An error occurred: {approvedError.message}</div>
            ) : null}
            <NewLoadingBackdrop
              open={approvedLoading}
              // open={true}
              styles={{
                marginTop: "12.5rem",
                ml: "20.0rem",
                mr: "2.5rem",
                borderRadius: "0.75rem",
              }}
            />
          </TabPanel>
          <TabPanel value={tabsValue} index={1}>
            {loadedUnApprovedDoctors?.length < 1 && (
              <Empty
                altText="Unapproved doctors list is empty"
                title="No unapproved doctor found, please sign up a doctor at: "
                linkUrl="https://doctor.cloudclinic.ng/"
              />
            )}
            {loadedUnApprovedDoctors?.length > 0 && (
              <DoctorPageTable
                id="unApprovedDoctors"
                selected={selected.unApprovedDoctors}
                filterName={filterName.unApprovedDoctors}
                handleFilterByName={handleFilterByName}
                filterRole={filterRole.unApprovedDoctors}
                handleFilterByRole={handleFilterByRole}
                order={order.unApprovedDoctors}
                orderBy={orderBy.unApprovedDoctors}
                handleRequestSort={handleRequestSort}
                handleSelectAllClick={handleSelectAllClick}
                filteredUsers={filteredUnApproved}
                mainList={loadedUnApprovedDoctors}
                rowsPerPage={rowsPerPage.unApprovedDoctors}
                handleClick={handleClick}
                handleOpenMenu={handleOpenMenu}
                emptyRows={emptyRowsUnApproved}
                isNotFound={unApprovedIsNotFound}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                page={page.unApprovedDoctors}
                open={open.unApprovedDoctors}
                handleCloseMenu={handleCloseMenu}
                TABLE_HEAD={UNAPPROVED_TABLE_HEAD}
                approvalRequestHandler={approvalRequestHandler}
                rejectRequestHandler={rejectRequestHandler}
                isLoading={isLoading}
                tabsValue={tabsValue}
                deactivateRequestHandler={deactivateRequestHandler}
                specializations={specializations}
                ApproveLmdLoading={ApproveLmdLoading}
                rejectLmdLoading={rejectLmdLoading}
              />
            )}
            {unApprovedIsError ? (
              <div>An error occurred: {unApprovedError.message}</div>
            ) : null}
            <NewLoadingBackdrop
              open={unApprovedLoading}
              // open={true}
              styles={{
                marginTop: "12.5rem",
                ml: "20.0rem",
                mr: "2.5rem",
                borderRadius: "0.75rem",
              }}
            />
          </TabPanel>
          {/* <TabPanel value={tabsValue} index={2}>
            {activeDoctors?.length < 1 && (
              <Empty
                altText="active doctors list is empty"
                title="No active doctor found"
              />
            )}
            {activeDoctors?.length > 0 && (
              <DoctorPageTable
                id="active"
                selected={selected.active}
                filterName={filterName.active}
                handleFilterByName={handleFilterByName}
                filterRole={filterRole.active}
                handleFilterByRole={handleFilterByRole}
                order={order.active}
                orderBy={orderBy.active}
                handleRequestSort={handleRequestSort}
                handleSelectAllClick={handleSelectAllClick}
                filteredUsers={filteredActive}
                mainList={activeDoctors}
                rowsPerPage={rowsPerPage.active}
                handleClick={handleClick}
                handleOpenMenu={handleOpenMenu}
                emptyRows={emptyRowsActive}
                isNotFound={activeIsNotFound}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                page={page.active}
                open={open.active}
                handleCloseMenu={handleCloseMenu}
                TABLE_HEAD={ACTIVE_TABLE_HEAD}
                deactivateRequestHandler={deactivateRequestHandler}
                isLoading={isLoading}
                tabsValue={tabsValue}
                specializations={specializations}
                deactivateLmdMutationError={deactivateLmdMutationError}
                deactivateLmdLoading={deactivateLmdLoading}
              />
            )}
            {activeIsError ? (
              <div>An error occurred: {activeError.message}</div>
            ) : null}
            <NewLoadingBackdrop
              open={activeLoading}
              // open={true}
              styles={{
                marginTop: "12.5rem",
                ml: "20.0rem",
                mr: "2.5rem",
                borderRadius: "0.75rem",
              }}
            />
          </TabPanel> */}
          <TabPanel value={tabsValue} index={2}>
            {deactivatedDoctors?.length < 1 && (
              <Empty
                altText="active doctors list is empty"
                title="No active doctor found"
              />
            )}
            {deactivatedDoctors?.length > 0 && (
              <DoctorPageTable
                id="deActive"
                selected={selected.deActive}
                filterName={filterName.deActive}
                handleFilterByName={handleFilterByName}
                filterRole={filterRole.deActive}
                handleFilterByRole={handleFilterByRole}
                order={order.deActive}
                orderBy={orderBy.deActive}
                handleRequestSort={handleRequestSort}
                handleSelectAllClick={handleSelectAllClick}
                filteredUsers={filteredUnActive}
                mainList={deactivatedDoctors}
                rowsPerPage={rowsPerPage.deActive}
                handleClick={handleClick}
                handleOpenMenu={handleOpenMenu}
                emptyRows={emptyRowsDeactive}
                isNotFound={deActiveIsNotFound}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                page={page.deActive}
                open={open.deActive}
                handleCloseMenu={handleCloseMenu}
                TABLE_HEAD={DEACTIVE_TABLE_HEAD}
                deactivateRequestHandler={deactivateRequestHandler}
                reactivateRequestHandler={reactivateRequestHandler}
                isLoading={isLoading}
                tabsValue={tabsValue}
                specializations={specializations}
                deactivateLmdMutationError={deactivateLmdMutationError}
                deactivateLmdLoading={deactivateLmdLoading}
                text={text}
                setText={setText}
              />
            )}
            {activeIsError ? (
              <div>An error occurred: {activeError.message}</div>
            ) : null}
            <NewLoadingBackdrop
              open={activeLoading}
              // open={true}
              styles={{
                marginTop: "12.5rem",
                ml: "20.0rem",
                mr: "2.5rem",
                borderRadius: "0.75rem",
              }}
            />
          </TabPanel>
          <TabPanel value={tabsValue} index={3}>
            {loadedRejectedDoctors?.length < 1 && (
              <Empty
                altText="doctors list is empty"
                title="No approved doctor found, please approve a doctor from the unapproved doctor list"
              />
            )}
            {loadedRejectedDoctors?.length > 0 && (
              <DoctorPageTable
                id="rejected"
                selected={selected.rejected}
                filterName={filterName.rejected}
                handleFilterByName={handleFilterByName}
                filterRole={filterRole.rejected}
                handleFilterByRole={handleFilterByRole}
                order={order.rejected}
                orderBy={orderBy.rejected}
                handleRequestSort={handleRequestSort}
                handleSelectAllClick={handleSelectAllClick}
                filteredUsers={filteredRejected}
                mainList={loadedRejectedDoctors}
                rowsPerPage={rowsPerPage.rejected}
                handleClick={handleClick}
                handleOpenMenu={handleOpenMenu}
                emptyRows={emptyRowsRejected}
                isNotFound={rejectedIsNotFound}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                page={page.rejected}
                open={open.rejected}
                handleCloseMenu={handleCloseMenu}
                isLoading={isLoading}
                TABLE_HEAD={REJECTED_TABLE_HEAD}
                tabsValue={tabsValue}
                specializations={specializations}
                refetchLoadedRejectedDoctors={refetchLoadedRejectedDoctors}
              />
            )}
            {rejectedIsError ? (
              <div>An error occurred: {rejectedError.message}</div>
            ) : null}
            <NewLoadingBackdrop
              open={rejectedLoading}
              // open={true}
              styles={{
                marginTop: "12.5rem",
                ml: "20.0rem",
                mr: "2.5rem",
                borderRadius: "0.75rem",
              }}
            />
          </TabPanel>
          <TabPanel value={tabsValue} index={4}>
            {scoreAppDoctors?.length < 1 && (
              <Empty
                altText="doctors list is empty"
                title="No doctor for the score app found, please add a doctor from the approved doctor list"
              />
            )}
            {scoreAppDoctors?.length > 0 && (
              <DoctorPageTable
                id="scoreApp"
                selected={selected.scoreApp}
                filterName={filterName.scoreApp}
                handleFilterByName={handleFilterByName}
                filterRole={filterRole.scoreApp}
                handleFilterByRole={handleFilterByRole}
                order={order.scoreApp}
                orderBy={orderBy.scoreApp}
                handleRequestSort={handleRequestSort}
                handleSelectAllClick={handleSelectAllClick}
                filteredUsers={filteredScoreApp}
                mainList={scoreAppDoctors}
                rowsPerPage={rowsPerPage.scoreApp}
                handleClick={handleClick}
                handleOpenMenu={handleOpenMenu}
                emptyRows={emptyRowsScoreApp}
                isNotFound={scoreAppIsNotFound}
                handleChangePage={handleChangePage}
                deactivateRequestHandler={deactivateRequestHandler}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                page={page.scoreApp}
                open={open.scoreApp}
                handleCloseMenu={handleCloseMenu}
                isLoading={isLoading}
                TABLE_HEAD={SCORE_TABLE_HEAD}
                tabsValue={tabsValue}
                specializations={specializations}
                deactivateLmdMutationError={deactivateLmdMutationError}
                deactivateLmdLoading={deactivateLmdLoading}
                text={text}
                setText={setText}
                removeScoreDoctorLoading={removeScoreDoctorLoading}
                removeScoreDoctorHandler={removeScoreDoctorHandler}
              />
            )}
            {approvedIsError ? (
              <div>An error occurred: {scoreAppError.message}</div>
            ) : null}
            <NewLoadingBackdrop
              open={scoreAppLoading}
              // open={true}
              styles={{
                marginTop: "12.5rem",
                ml: "20.0rem",
                mr: "2.5rem",
                borderRadius: "0.75rem",
              }}
            />
          </TabPanel>
        </Card>
      </Container>
      {/* </LoadingBackdrop> */}
    </>
  );
}
