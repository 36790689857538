import React, { useState, useEffect } from "react";
import { Input } from "../../../components/shadch/ui/ui/input";
import { Search } from "lucide-react";
import AddNewInsurer from "./AddNewInsurer";
import InsurerDeleteModal from "./InsurerDeleteModal";
import { TablePagination } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import apiClient from "../../../http/common";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { PropagateLoader } from "react-spinners";

const searchSchema = z.object({
  searchQuery: z.string().optional(),
});

const HealthInsurer = () => {
  const [heirsData, setHeirsData] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // Filtered data for search results
  const [loading, setLoading] = useState(false); // Loading state
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [insurerToEdit, setInsurerToEdit] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [insurerToDelete, setInsurerToDelete] = useState(null);
  const navigate = useNavigate();

  const form = useForm({
    resolver: zodResolver(searchSchema),
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: {
      searchQuery: "",
    },
  });

  const fetchInsurers = async () => {
    setLoading(true); // Start loading
    try {
      const response = await apiClient.get("/sponsor/sponsor/GetAllInsurers");
      setHeirsData(response?.data?.data);
      setFilteredData(response?.data?.data); // Initially set filtered data to all insurers
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    fetchInsurers();
  }, []);

  const handleSearchChange = async (event) => {
    const value = event.target.value;
    form.setValue("searchQuery", value);

    // Filter the data based on the search query
    const filtered = heirsData.filter((insurer) =>
      insurer.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered); // Update filtered data for display

    // Update URL search params if needed
    const isValid = await form.trigger("searchQuery");
    if (isValid) {
      setSearchParams({ searchQuery: value });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const openEditModal = (insurer) => {
    setInsurerToEdit(insurer);
    setIsEditMode(true);
    setIsModalOpen(true);
  };

  const openCreateModal = () => {
    setInsurerToEdit(null);
    setIsEditMode(false);
    setIsModalOpen(true);
  };

  const openDeleteModal = (insurer) => {
    setInsurerToDelete(insurer);
    setIsDeleteModalOpen(true);
  };

  return (
    <div className="mt-10">
      {loading && (
        <div className="flex justify-center items-center h-64">
          <PropagateLoader />
        </div>
      )}
      {!loading && (
        <>
          <div className="w-full flex items-center">
            <div className={"w-full relative"}>
              <form>
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
                <Input
                  type="text"
                  {...form.register("searchQuery")}
                  placeholder="Search..."
                  onChange={handleSearchChange} // Use updated search handler
                  className="w-2/5 pl-12 py-6"
                />
              </form>
            </div>
            <button
              onClick={openCreateModal}
              className="flex items-center justify-center text-white bg-[#2CBCEF] py-2 px-8 whitespace-nowrap rounded-lg"
            >
              + Create New
            </button>
          </div>

          <table className="text w-full mt-10">
            <thead className="text w-full bg-[#FAFAFA] h-10 border-b">
              <tr className="text-[#4A4A4A] font-bold">
                <td className="text-start w-1/4 pl-2">Name</td>
                <td className="text-start">Email Address</td>
                <td className="text-start">Phone</td>
                <td className="text-start">Action</td>
              </tr>
            </thead>
            <tbody className="text-start text-[13px] text-gray-600 w-full">
              {filteredData?.length > 0 ? (
                filteredData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((tab, index) => (
                    <tr
                      key={index}
                      className={`text ${(index + 1) % 2 ? "bg-white" : "bg-[#FAFAFA]"}`}
                    >
                      <td className="text-[#444448] pl-5 py-5">{tab?.name}</td>
                      <td className="text-[#444448]">{tab?.email}</td>
                      <td className="text-[#444448]">
                        {tab?.phoneNumber ? tab?.phoneNumber : "--------"}
                      </td>
                      <td className="text-[#444448]">
                        <button
                          onClick={() => navigate(`/health-insurer-details/${tab?.id}`, { state: tab })}
                          className="text-[#2CBCEF] mr-2"
                        >
                          View
                        </button>
                        <button
                          onClick={() => openEditModal(tab)}
                          className="text-[#808080] mr-2"
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => openDeleteModal(tab)}
                          className="text-red-500"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan="4" className="text-center py-5">
                    No insurers found
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <div className="text bg-white w-full border-t">
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filteredData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(event, newPage) => handleChangePage(event, newPage)}
              onRowsPerPageChange={(event) => handleChangeRowsPerPage(event)}
            />
          </div>
        </>
      )}

      {/* Insurer Modal for both Create and Edit */}
      {isModalOpen && (
        <AddNewInsurer
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          insurerToEdit={insurerToEdit}
          isEditMode={isEditMode}
          onSuccess={fetchInsurers}
        />
      )}

      {/* Delete Modal */}
      {isDeleteModalOpen && (
        <InsurerDeleteModal
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          roleName={insurerToDelete?.name}
          onDelete={async () => {
            try {
              await apiClient.post(
                `sponsor/DeleteInsurer?InsurerId=${insurerToDelete.id}`
              );
              toast.success("Insurer deleted successfully");
              fetchInsurers(); // Refresh the list without reloading
              setIsDeleteModalOpen(false);
            } catch (error) {
              toast.error("Failed to delete insurer");
            }
          }}
        />
      )}
    </div>
  );
};

export default HealthInsurer;
