import React, { useEffect, useState } from "react";
import { BsFilter } from "react-icons/bs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import backArrow from "../icons/backArrow.svg";
import usePlans from "./usePlans";
import { IoMdCheckmark } from "react-icons/io";
// import { MdKeyboardArrowRight } from "react-icons/md";
import EmptyComponent from "../plans/EmptyComponent";
import EmptyPlan from "../icons/plansIcon.svg";
// import useFormHook from "./useFormHook";
// import CreatePlanFormModal from "../../components/CreatePlanFormModal";
// import Modal from "../../components/Modal";
import { useQuery } from "react-query";
import apiClient from "../../http/common";
import Modal from "./Modal";
import CreatePlanFormModal from "./CreatePlanFormModal";

const PlanDetails = () => {
  const navigate = useNavigate();

  const {
    planMode,
    data,
    arrayAsString,
    setArrayAsString,
    setEditedBusinessRules,
  } = usePlans();

  const { state } = useLocation();
  const { id } = useParams();
  const [currentData, setCurrentData] = useState({});
  const [showCreatePlanFormModal, setshowCreatePlanFormModal] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
//   console.log(state);

  useEffect(() => {
    // Mapping all datas to rearrange and distructure it into a usable format
    const transaction = data?.data?.data?.data?.map((item) => {
      let coverages = item?.coverages?.map((obj) => obj.coverageName);
      const firstCoverage = coverages?.[0] + ", ...";

      coverages = coverages?.join(", ");
      return {
        ...item,
        startDate: formatDate(item.startDate),
        expiryDate: formatDate(item.expiryDate),
        coverages,
        firstCoverage,
        isChecked: false,
      };
    });


    const openModal = () => {
        setshowCreatePlanFormModal(true);
      };
    
      const closeModal = () => {
        setshowCreatePlanFormModal(false);
      };
    // Filtering out the current data clicked to view
    const filteredData = transaction?.filter(
      (filtrate) => filtrate.planId === id
    );

    if (filteredData) {
      const { updatedAt, ...rest } = filteredData[0] || {};
      setCurrentData(rest);
    }
  }, [id, data]);

  const handleCheckboxChange = (id) => {
    const updatedCheckboxes = checkboxes.map((checkbox) => {
      return checkbox.coverageId === id
        ? { ...checkbox, isChecked: !checkbox.isChecked }
        : checkbox;
    });
    setCheckboxes(updatedCheckboxes);
  };
  const checkboxesonEdit = () => {
    const modifiedData = data?.data?.data?.data?.map((item) => {
      if (state?.coverageIds?.includes(item.coverageId)) {
        return { ...item, isChecked: true };
      }
      return item;
    });

    setCheckboxes(modifiedData);
  };

  const mappedOptions = state?.tarrifs?.map((value, index) => {
    return value.tarrifName;
  });


  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return day + "/" + month + "/" + year;
  }

  useEffect(() => {
    let dataSponsor;
    if (state?.businessRules) {
      const stringRepresentation = state?.businessRules?.split(",");
      const formattedItems = stringRepresentation
        ?.map((item) => `${item}`)
        ?.join("\n");
      setArrayAsString(formattedItems);
    } else {
      const stringRepresentation = dataSponsor?.data?.businessRules?.split(",");
      const formattedItems = stringRepresentation
        ?.map((item) => `${item}`)
        ?.join("\n");
      setArrayAsString(formattedItems);
    }
  }, [
    // items,
    setArrayAsString,
    state,
  ]);

  const handleEditFunction = (obj) => {
    setEditedBusinessRules(
      state?.businessRules?.split("\n")?.join("\n")?.split("\n")?.join(",")
    );
    // console.log(obj);
  };


  // this is to format date into desire=able format
  const dateFormatter = (date) =>{
    date = new Date(date);
    const day = String(date.getDay()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day} / ${month} / ${year}`
  };
  return (
    <div className="h-[85%] p-[1rem] example overflow-auto box-border w-full">
      <div className="w-full min-h-screen pl-8 text-xs bg-gray-50">
        <div className="flex items-center justify-between mt-3 text">
          <div
            onClick={() => navigate(-1)}
            className="flex items-center justify-start gap-2 cursor-pointer text-secondary ">
            <img src={backArrow} alt="bag" className="h-[14px] w-[14px]" />
            <span className="">GO BACK</span>
          </div>
          <button onClick={() =>setshowCreatePlanFormModal(true)} className="text-white bg-[#2CBCEF] py-2 px-4 rounded ">+ Publish on My Cloud Clinic</button>
        </div>
        <div className="p-1 mt-3 text-sm">
          <h1 className="my-3 font-semibold text-blue-800">Plan Details</h1>
          {/* <h1 className="py-3 font-bold text-lg text-[#343673] bg-gray-100 w-11/12 text-center">
            Remaining Allowed Amount: N 25,000,000
          </h1> */}
          <div className="w-11/12 px-10 py-3 text-xs bg-white rounded-lg ">
            <div className="flex flex-wrap justify-between text columns-3">
              <div className="text ">
                <div className="my-4 text">
                  <h3 className="font-semibold text text-slate-600">
                    Plan Name
                  </h3>
                  <span className="text-slate-500">{state?.planName}</span>
                </div>
                <div className="my-4 text">
                  <h3 className="font-semibold text text-slate-600">
                    Maximum Amount Per Month
                  </h3>
                  <span className="text-slate-500">
                    N {state?.maxAmountPerIndividualPerMonth}
                  </span>
                </div>
              </div>
              <div className="text">
                <div className="my-4 text">
                  <h3 className="font-semibold text text-slate-600">
                    Out Of Pocket Maximum
                  </h3>
                  <span className="text-slate-500">
                    N {state?.out_of_PocketMax}
                  </span>
                </div>
                <div className="my-4 text">
                  <h3 className="font-semibold text text-slate-600">
                    Deductibles
                  </h3>
                  <span className="text-slate-500">N {state?.deductibles}</span>
                </div>
              </div>
              <div className="text">
                <div className="my-4 text">
                  <h3 className="font-semibold text text-slate-600">
                    Expiry Date
                  </h3>
                  <span className="text-slate-500">{dateFormatter(state?.expiryDate)}</span>
                </div>
                <div className="my-4 text">
                  <h3 className="font-semibold text text-slate-600">
                    Maximum Amount Per Individual
                  </h3>
                  <span className="text-slate-500">
                    N {state?.maxAmountPerIndividual}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap justify-between text columns-3">
              <div className={` `}>
                <h1 className="my-3 font-semibold text-slate-600">
                  Coverage(s)
                </h1>
                <div className="flex items-center mb-3 text-slate-600">
                  {state?.coverages?.length > 0 ? (
                    <div className="text">
                      {state?.coverages?.map((value, index) => (
                        <div key={index} className="flex mb-3 text">
                          <span className="text bg-sky-400 rounded-full p-0.5 mr-1">
                            <IoMdCheckmark className="text-white" />
                          </span>
                          <span className="text">{value?.coverageName}</span>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="flex justify-center items-center font-[700] text-[18px] text-[#808080] ">
                      <EmptyComponent
                        className={"w-20"}
                        svgPicture={EmptyPlan}
                        title={""}
                        caption={""}></EmptyComponent>
                    </div>
                  )}
                </div>
              </div>
              <div className={`lg:w-1/4`}>
                <h1 className="my-3 font-semibold text-slate-600">Tariff(s)</h1>
                <div className="flex items-center mb-3 text-slate-600">
                  {mappedOptions?.length > 0 ? (
                    <div className="text">
                      {mappedOptions?.map((value) => (
                        <div className="flex mb-3 text">
                          <span className="text bg-sky-400 rounded-full p-0.5 mr-1">
                            <IoMdCheckmark className="text-white" />
                          </span>
                          <span className="text">{value}</span>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="flex justify-center items-center font-[700] text-[18px] text-[#808080] ">
                      <EmptyComponent
                        className={"w-20"}
                        svgPicture={EmptyPlan}
                        title={""}
                        caption={""}></EmptyComponent>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <h1 className="mt-5 text-lg">Business Rules</h1>
            <textarea
              className="w-full p-3 text-sm border-0 rounded outline-0"
              value={arrayAsString}
              id=""
              rows="10"
              readOnly></textarea>
          </div>
        </div>
      </div>
      <Modal 
        isOpen={showCreatePlanFormModal}
        openModal={() => setshowCreatePlanFormModal(true)}
        closeModal={() => setshowCreatePlanFormModal(false)}
        >
        <CreatePlanFormModal
        closeModal={() => setshowCreatePlanFormModal(false)}
        planMode={planMode}
        state={state}
        handleCheckboxChange={handleCheckboxChange}
        />
      </Modal>
    </div>
  );
};

export default PlanDetails;
