// import {useForm } from "react-hook-form";
import { z } from "zod";
// import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import apiClient from "../http/common";
import { useSelector } from "react-redux";

const formSchema = z.object({
  policyNumber: z.string().min(2, { message: "Policy number is required" }),
  product: z.string().min(2, { message: "Product is required" }),
  title: z.string().min(2, { message: "Title is required" }),
  PhoneNumber: z.string().min(2, { message: "Phone number is required" }),
  SurName: z.string().min(2, { message: "Surname is required" }),
  firstName: z.string().min(2, { message: "First name is required" }),
  middleName: z.string().min(2, { message: "Middle name is required" }),
  email: z.string().email(),
  file: z.string().min(2, { message: "Certificate is required" }),
});

const useClaimHook = () => {
  const [fileInfo, setfileInfo] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [claims, setClaims] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");

  const onSubmit = (data) => {
    console.log(data);
  };

  useEffect(() => {
    setIsLoading(true);
    apiClient.get(`/sponsor/GetAllManualClaims?page=${1}&size=${5}`)
    .then((response) => {
      setClaims(response.data.data);
      setIsLoading(false);
  })
  .catch((error) => {
    console.log(error);
  }).finally(() => {
    setIsLoading(false);
  });
  
  }, [])
  

  const onDrop = useCallback((acceptedFiles) => {
    const data = new FormData();
    acceptedFiles.forEach((file) => {
      data.append("files", file);
      const reader = new FileReader();

      reader.onload = () => {
        const binaryStr = reader.result;
        console.log(binaryStr);
      };
      reader.readAsArrayBuffer(file);
    });
    setfileInfo(acceptedFiles[0]);
    console.log(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: [".xlsx", ".xls"],
  });


  const handleChanges = (e) => {
    console.log(e);
    
  };

  const handleSelect = (value) => {
    setSelectedItem(value);
    setIsOpen(true);
  };

  
  return {
    fileInfo,
    onSubmit,
    formSchema,
    getRootProps,
    getInputProps,
    isDragActive,
    handleChanges,
    claims,
    isLoading,
    setIsOpen,
    isOpen,
    selectedItem, 
    setSelectedItem,
    handleSelect
  };
};

export default useClaimHook;
