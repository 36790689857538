import { useEffect, useMemo, useState } from "react";
import { useHttpClient } from "../../../hooks/http-hook";
import { applySortFilter } from "./SponsorHelperFunctions";
import { baseUrl, getComparator } from "../../../utils/helper";
import { toast } from "react-toastify";
import { reactAppSubscriptionKey } from "../../../http/common";

function useSponsor(auth) {
  const { token } = auth;
  const [success, setSuccess] = useState({});
  const [openCreatePage, setOpenCreatePage] = useState(false);
  const SponsorPageSwitch = (event) => setOpenCreatePage((page) => !page);
  const [tabsValue, setTabsValue] = useState(0);
  const [priceSuccess, setPriceSuccess] = useState({});
  const [isSponsorLoading, setIsSponsorLoading] = useState(false);
  const [sponsorList, setSponsorList] = useState();
  const [sponsorDetail, setSponsorDetail] = useState();

  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccess((prev) => ({
      value: false,
      message: "",
    }));
    if (event) {
      event.preventDefault();
    }
  };

  const handleTabsValueChange = (event, newValue) => {
    setTabsValue(newValue);
  };

  useEffect(() => {
    const fetchSponsors = async () => {
      setIsSponsorLoading(true);
      try {
        const responseData = await sendRequest(
          `${baseUrl}/sponsor/GetSponsors`,
          undefined,
          undefined,
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "Ocp-Apim-Subscription-Key": reactAppSubscriptionKey,
          }
        );
        setSponsorList(responseData?.data.data);
        setIsSponsorLoading(false);
      } catch (err) {}
    };
    fetchSponsors();
  }, [sendRequest, token, success.value]);

  const getSponsorDetails = async (id) => {
    try {
      const responseData = await sendRequest(
        `${baseUrl}/sponsor/GetASponsor/${id}`,
        undefined,
        undefined,
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
          "Ocp-Apim-Subscription-Key": reactAppSubscriptionKey,
        }
      );
      setSponsorDetail(responseData);
    } catch (err) {}
  };

  const enableSponsorPricing = async (
    sponsorId,
    enableCancCreatePricingTier
  ) => {
    const data = {
      sponsorId: sponsorId,
      enableCancCreatePricingTier: enableCancCreatePricingTier,
    };
    try {
      const responseData = await sendRequest(
        `${baseUrl}/sponsor/CanCreatePriceTier`,
        "POST",
        JSON.stringify(data),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
          "Ocp-Apim-Subscription-Key": reactAppSubscriptionKey,
        }
      );
      toast.success(responseData?.message, {
        position: "top-center",
        hideProgressBar: true,
        autoClose: 2000,
      });
      setPriceSuccess({
        value: responseData?.isSuccessful,
        message: responseData?.message,
      });
    } catch (err) {
      toast.error(err.message, {
        position: "top-center",
      });
    }
  };

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("sponsorName");

  const [filterName, setFilterName] = useState("");

  const [filterRole, setFilterRole] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = (id) => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = sponsorList.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
    console.log(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - sponsorList.length) : 0;

  const handleFilterByRole = (event, id) => {
    setPage(0);
    setFilterRole(event.target.value);
  };

  const filteredSponsors = useMemo(
    () =>
      !!sponsorList &&
      applySortFilter(
        sponsorList,
        getComparator(order, orderBy),
        filterName,
        filterRole
      ),
    [sponsorList, order, orderBy, filterName, filterRole]
  );

  const isNotFound = !filteredSponsors.length && !!filterName;

  return {
    sponsorList,
    isLoading,
    error,
    clearError,
    open,
    page,
    order,
    selected,
    orderBy,
    filterName,
    filterRole,
    rowsPerPage,
    tabsValue,
    handleTabsValueChange,
    handleOpenMenu,
    handleCloseMenu,
    isNotFound,
    handleSelectAllClick,
    emptyRows,
    filteredSponsors,
    handleFilterByRole,
    handleFilterByName,
    handleChangeRowsPerPage,
    handleChangePage,
    handleClick,
    handleRequestSort,
    success,
    handleSnackbarClose,
    openCreatePage,
    SponsorPageSwitch,
    getSponsorDetails,
    sponsorDetail,
    priceSuccess,
    enableSponsorPricing,
    isSponsorLoading,
  };
}

export default useSponsor;
