import React, { useContext } from "react";
import useSponsor from "./SponsorHook";
import { AuthContext } from "../../../context/auth-context";
import { TablePagination } from "@mui/material";
import SponsorListToolbar from "./SponsorListToolBar";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Oval } from "react-loader-spinner";

SponsorListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
};

const SponsorCustomTable = ({ numSelected, filterName, onFilterName }) => {
  const auth = useContext(AuthContext);
  const {
    id: tableId,
    sponsorList,
    page,
    selected,
    rowsPerPage,
    filteredSponsors,
    handleFilterByName,
    handleChangeRowsPerPage,
    handleChangePage,
    isSponsorLoading,
  } = useSponsor(auth);
  const empptyPage = [];
  const navigate = useNavigate();


  console.log(filteredSponsors);
  
  const dateFormatter = (date) => {
    date = new Date(date);
    const day =  String(date.getDay()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <div>
          {!isSponsorLoading ? (
      <>
        <div className="flex items-center mt-10 mb-5 text">
          <SponsorListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={(event) => handleFilterByName(event, tableId)}
          />
        </div>

          <table className="w-full mt-3 text">
            <thead className="text w-full bg-[#FAFAFA] h-10 border-b">
              <tr className="text-[#4A4A4A] font-bold">
                <td className="w-1/4 pl-2 text-start">Sponsor's Name</td>
                <td className="text-start">Date of Reg.</td>
                <td className="text-start">Phone</td>
                <td className="text-start">Email Address</td>
                <td className="text-start">Action</td>
              </tr>
            </thead>
            <tbody className="text-start text-[13px] text-gray-600 w-full">
              {sponsorList?.length === 0 &&
                empptyPage.map((value, index) => (
                  <tr
                    className={`animate animate-pulse text w-full h-6 ${
                      (index + 1) % 2
                        ? "bg-white text-white"
                        : "bg-[#dddddd] text-[#dddddd]"
                    }  `}
                  >
                    <td className="py-3 pl-4 animate animate-pulse text">
                      {value}
                    </td>
                    <td className="py-3 pl-4 animate animate-pulse text">
                      {value}
                    </td>
                    <td className="animate animate-pulse text ">{value}</td>
                    <td className="animate animate-pulse text ">{value}</td>
                    <td className="animate animate-pulse text">
                      <span className="py-1 pl-1 pr-8 rounded-md animate animate-pulse text">
                        {value}
                      </span>
                    </td>
                    <td className="animate animate-pulse text flex items-center py-2.5 cursor-pointer">
                      <span className="text-xs font-normal text-red-500 animate animate-pulse hover:text-red-600 hover:scale-105">
                        {}
                      </span>
                    </td>
                  </tr>
                ))}
              {!!filteredSponsors &&
                filteredSponsors
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((tab, index) => (
                    <>
                      <tr
                        className={`text ${
                          (index + 1) % 2 ? "bg-white" : "bg-[#FAFAFA]"
                        }`}
                      >
                        <td className="text-[#444448]  pl-5 py-3">
                          {tab?.sponsorName}
                        </td>
                        <td className="text-[#444448]  pl-5 py-3">
                          {tab?.createdAt ? dateFormatter(tab?.createdAt) : "--------"}
                        </td>
                        <td className="text-[#444448]">
                          {tab?.phoneNumber ? tab?.phoneNumber : "--------"}
                        </td>
                        <td className="text-[#444448]">{tab?.email}</td>
                        <td className="text-[#444448]">
                          <button
                            onClick={() =>
                              navigate(`/sponsor-detail/${tab?.sponsorId}`, {
                                state: tab,
                              })
                            }
                            className="text-[#2CBCEF]"
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    </>
                  ))}
            </tbody>
          </table>

        <div className="w-full bg-white border-t text">
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredSponsors.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(event, newPage) =>
              handleChangePage(event, newPage, tableId)
            }
            onRowsPerPageChange={(event) =>
              handleChangeRowsPerPage(event, tableId)
            }
          />
        </div>
      </>
          ) : (
            <div className="flex justify-center h-[70vh] w-full items-center text">
              <Oval
                visible={true}
                height="100"
                width="100"
                color="#4fa94d"
                ariaLabel="oval-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </div>
          )}
    </div>
  );
};

export default SponsorCustomTable;
