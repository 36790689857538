import React, { useRef, useEffect, useState } from "react";
import { Input } from "../../../components/shadch/ui/ui/input";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../../../components/shadch/ui/ui/dialog";
import { Button } from "../../../components/shadch/ui/ui/button";
import { ScrollArea } from "../../../components/shadch/ui/ui/scroll-area";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../../components/shadch/ui/ui/form";
import { useForm } from "react-hook-form";
import uploadLogo from "../../../components/icons/uploadLogo.svg";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import apiClient from "../../../http/common";
import { toast } from "react-toastify";
import { ReloadIcon } from "@radix-ui/react-icons";

const formSchema = z.object({
  Id: z.string().optional(), // Added Id to schema
  SponsorName: z.string().min(1, { message: "Company Name is required" }),
  Email: z.string().email("Invalid email address"),
  PhoneNumber: z
    .string()
    .min(10, { message: "Phone number should at least be 10 digits" })
    .max(15, { message: "Phone number shouldn't be more than 15 digits" }),
});

const AddNewInsurer = ({ isModalOpen, setIsModalOpen, insurerToEdit, isEditMode }) => {
  const fileInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState("");

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      Id: "", // Id is always initialized as an empty string
      SponsorName: "",
      Email: "",
      PhoneNumber: "",
    },
  });

  useEffect(() => {
    if (isEditMode && insurerToEdit) {
      form.setValue("Id", insurerToEdit?.id || ""); // Set the insurer's Id when editing
      form.setValue("SponsorName", insurerToEdit?.name);
      form.setValue("Email", insurerToEdit?.email);
      form.setValue("PhoneNumber", insurerToEdit?.phoneNumber);
      setFileName(insurerToEdit?.InsurerLogo || "");
    } else {
      form.reset({
        Id: "", // Always reset Id to an empty string when creating a new insurer
        SponsorName: "",
        Email: "",
        PhoneNumber: "",
      });
      setFileName("");
    }
  }, [insurerToEdit, isEditMode, form]);

  const onSubmit = async (data) => {
    setIsLoading(true);
    const formData = new FormData();
  
    // Always include Id in the FormData, setting it to an empty string for new insurers
    formData.append("Id", isEditMode ? data?.Id : "");
    formData.append("Name", data?.SponsorName);
    formData.append("Email", data?.Email);
    formData.append("PhoneNumber", data?.PhoneNumber);
  
    if (fileInputRef.current.files[0]) {
      formData.append("InsurerLogo", fileInputRef.current.files[0]);
    }
  
    try {
      const endpoint = isEditMode ? `/sponsor/sponsor/EditInsurer` : `/sponsor/CreateInsurer`;
      const response = await apiClient.post(endpoint, formData);
  
      if (response?.status === 200 || response?.status === 201) {
        toast.success(response?.data?.message || `${isEditMode ? "Updated" : "Created"} successfully`);
        setIsModalOpen(false);
        // Reload or update the data here if necessary
      } else {
        toast.error("Failed to process the request. Please try again.");
      }
    } catch (error) {
      console.error("Error during API request:", error);
      toast.error("An error occurred. Please check the details and try again.");
    } finally {
      setIsLoading(false);
    }
  };
      
  const handleFileChange = () => {
    setFileName(fileInputRef.current.files[0]?.name);
  };

  const handleParentClick = () => {
    fileInputRef.current.click();
  };

  return (
    <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
      <DialogContent className="max-w-2xl">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <DialogHeader>
              <DialogTitle>{isEditMode ? "Edit Insurer" : "Create New Insurer"}</DialogTitle>
            </DialogHeader>
            <ScrollArea className="h-auto">
              <div id="form-fields" className="grid gap-8 px-3 mt-5">
                <FormField control={form.control} name="SponsorName" render={({ field }) => (
                  <FormItem>
                    <FormLabel>Name</FormLabel>
                    <FormControl>
                      <Input placeholder="Insurer name" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )} />
                <FormField control={form.control} name="Email" render={({ field }) => (
                  <FormItem>
                    <FormLabel>Email Address</FormLabel>
                    <FormControl>
                      <Input placeholder="Enter Email Address" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )} />
                <FormField control={form.control} name="PhoneNumber" render={({ field }) => (
                  <FormItem>
                    <FormLabel>Phone Number</FormLabel>
                    <FormControl>
                      <Input placeholder="Enter Phone Number" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )} />

                <div
                  className="border-dotted relative w-full border pt-2 bg-sky-50 border-sky-600 rounded-lg h-36 px-10 flex flex-col items-center"
                  onClick={() => handleParentClick()}
                >
                  <input
                    onChange={handleFileChange}
                    type="file"
                    className="w-full h-full absolute hidden"
                    ref={fileInputRef}
                    required={!isEditMode} // Require only in create mode
                  />
                  <img src={uploadLogo} alt="" className="text mb-1" />
                  {fileInputRef?.current?.files[0] ? (
                    <h1 className="text-green-600 text-sm">A Sponsor logo is attached</h1>
                  ) : (
                    <h1 className="text-red-600 text-sm">Click to upload A sponsor logo</h1>
                  )}
                  <div className="h-2"></div>
                  <button className="px-8 py-1 bg-white border border-sky-200 rounded-md text-sky-500 font-bold">
                    Select a file
                  </button>
                  <div className="h-1"></div>
                  <div className="text-sky-500 text-sm">( Max. file size: 20MB )</div>
                </div>
              </div>
            </ScrollArea>
            <div className="mt-2 px-5">
              <Button className={`w-full py-3 ${isLoading ? "bg-[#76d7fa]" : "bg-[#2CBCEF]"}`} type="submit">
                {isLoading ? (
                  <div className="flex items-center justify-center">
                    <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                    Submitting...
                  </div>
                ) : (
                  "Submit"
                )}
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default AddNewInsurer;
