import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { useFormikContext } from 'formik';

const FormikSubmitButton = ({
  children,
  ...otherProps
}) => {
  // const { isValid, submitForm, isSubmitting, dirty } = useFormikContext();
  const { isValid, submitForm, isSubmitting, values, errors } = useFormikContext();
  console.log('isValid', isValid, isSubmitting, values, 'errors', errors);
  const handleSubmit = () => {
    submitForm();
  }

  const configButton = {
    variant: 'contained',
    color: 'primary',
    loading: isSubmitting || otherProps.queryisloading === 'true',
    disabled: !isValid || isSubmitting,
    fullWidth: true,
    size: "large",
    ...otherProps,
    onClick: handleSubmit
  }

  return (
    <LoadingButton
      {...configButton}
      sx={{
        '&.MuiLoadingButton-loading': {
          '& .MuiLoadingButton-loadingIndicator': {
            color: '#2CBCEF'
          }
        }
      }}
    >
      {children}
    </LoadingButton>
  );
};

export default FormikSubmitButton;
