// component
import Iconify from "../../../components/iconify";
import SvgColor from "../../../components/svg-color";
import { createUnique } from "../../../utils/helper";
import { IoSettingsOutline } from "react-icons/io5";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const navConfig = [
  {
    title: "dashboard",
    path: "/dashboard",
    icon: icon("ic_analytics"),
  },
  {
    title: "doctor",
    path: "/doctors",
    icon: icon("ic_doctor"),
    // icon: icon('ic_doctor-male'),
    // icon: <iconify-icon icon="healthicons:doctor-male"></iconify-icon>
    // icon: <Iconify icon="healthicons:doctor-male" />
    // icon: <Icon icon="healthicons:doctor-male" />
  },
  {
    title: "partner",
    path: "/partners",
    icon: icon("ic_partner"),
  },
  {
    title: "admins",
    path: "/admins",
    icon: icon("ic_admin"),
  },
  {
    title: "product",
    path: "/products",
    icon: icon("ic_cart"),
  },
  {
    title: "blog",
    path: "/blog",
    icon: icon("ic_blog"),
  },
  {
    title: "login",
    path: "/login",
    icon: icon("ic_lock"),
  },
  {
    title: "Not found",
    path: "/404",
    icon: icon("ic_disabled"),
  },
];

const configNav = (permissions) => {
  const unique = createUnique(permissions);
  let childrenList = [
    {
      title: "dashboard",
      path: "/dashboard",
      icon: icon("ic_analytics"),
    },
    // {
    //   title: 'product',
    //   path: '/products',
    //   icon: icon('ic_cart'),
    // },
    // {
    //   title: 'blog',
    //   path: '/blog',
    //   icon: icon('ic_blog'),
    // },
    {
      title: "patient",
      path: "/patients",
      icon: icon("ic_patient"),
    },
    {
      title: "transaction",
      path: "/transactions",
      icon: icon("ic_transaction"),
    },
    {
      title: "consultation",
      path: "/consultations",
      icon: icon("ic_consultation"),
    },
    {
      title: "Contract",
      path: "/contract",
      icon: icon("ic_contract"),
    },
    {
      title: "Appointment",
      path: "/appointments",
      icon: icon("ic_appointment"),
    },
    {
      title: "Sponsors",
      path: "/sponsors",
      icon: icon("ic_sponsor"),
    },
    {
      title: "Health Insurance Companies",
      path: "/health-insurance-companies",
      icon: icon("health"),
    },
    {
      title: "Health Insurer",
      path: "/health-insurer",
      icon: icon("health"),
    },
    {
      title: "Claims",
      path: "/claims",
      icon: icon("moneyIcon"),
    },

    {
      title: "Notifications",
      path: "/notification",
      icon: icon("notificationIcon"),
    },
    {
      title: "Roles & Permission",
      path: "/permission",
      icon: icon("users-profiles-01"),
    },


    // {
    //   title: 'Not found',
    //   path: '/404',
    //   icon: icon('ic_disabled'),
    // },
  ];
  unique.forEach((permission) => {
    // console.log('permission', permission)
    if (permission.includes("Lmd")) {
      childrenList.push({
        title: "doctor",
        path: "/doctors",
        icon: icon("ic_doctor"),
        // icon: icon('ic_doctor-male'),
        // icon: <iconify-icon icon="healthicons:doctor-male"></iconify-icon>
        // icon: <Iconify icon="healthicons:doctor-male" />
        // icon: <Icon icon="healthicons:doctor-male" />
      });
    }
    if (permission.includes("Facility")) {
      childrenList.push({
        title: "facility",
        path: "/facility",
        icon: icon("ic_facility"),
      });
    }
    if (permission.includes("Partner")) {
      childrenList.push({
        title: "partner",
        path: "/partners",
        icon: icon("ic_partner"),
      });
    }
    if (permission.includes("Permission")) {
      childrenList.push({
        title: "admins",
        path: "/admins",
        icon: icon("ic_admin"),
      });
    }
  });
  childrenList.push({
    title: "Settings",
    path: "/settings",
    icon: icon("ic_setting"),
  });

  return childrenList;
};

export default configNav;
