import { useState } from "react";
// @mui
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Popover from "@mui/material/Popover";
import Checkbox from "@mui/material/Checkbox";
import TableRow from "@mui/material/TableRow";
import MenuItem from "@mui/material/MenuItem";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
// components
import Label from "../../../components/label";
import Iconify from "../../../components/iconify";
import Scrollbar from "../../../components/scrollbar";
// mock
import TransitionsModal from "../consultation/ConsultationModal";
// import ConsultationListToolbar from "./ConsultationListToolbar";
import AppointmentListToolbar from "./AppointmentListToolbar";
import AppointmentListHead from "./AppointmentListHead";
import { Button } from "@mui/material";
import { CSVLink } from "react-csv";

// ----------------------------------------------------------------------

export default function AppointmentTable({
  id: tableId,
  title,
  selected,
  filterName,
  facilityName,
  handleFilterByName,
  filterRole,
  handleFilterByRole,
  order,
  orderBy,
  handleRequestSort,
  handleSelectAllClick,
  filteredUsers,
  mainList,
  rowsPerPage,
  handleClick,
  handleOpenMenu,
  emptyRows,
  isNotFound,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  open,
  openCsv,
  setOpenCsv,
  handleCloseMenu,
  TABLE_HEAD,
  approvalRequestHandler,
  tabsValue,
  isLoading,
  csvLink,
  startDate,
  endDate,
  handleDateChange,
  handleExportToCSV,
}) {
  const [consultationId, setConsultationId] = useState(null);
  const [selectedConsultation, setSelectedConsultation] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  ////

  const [facilityInput, setFacilityInput] = useState(null);
  const [selectedFacility, setSelectedFacility] = useState(null);

  const handleModalOpen = (doc) => {
    setSelectedFacility(doc);
    // setOpenModal(true);
  };
  const handleModalClose = () => setOpenModal(false);

  const specialization = mainList.map((n) => n?.category);
  const specializationsToEvaluate = [...new Set(specialization)];
  let consultationSpecializations = specializationsToEvaluate.map((sp) => ({
    id: sp,
    name: sp,
  }));

  const filtered = filteredUsers.filter((user) => user.status !== "unaccepted" && user.status !== "unpaid" && user.status !== "rejected" && user.status !== "pendingReschedule" && user.status !== "rescheduled");
  console.log(filtered);
  
  
  return (
    <>
      <Box sx={{}}>
        <AppointmentListToolbar
          numSelected={selected.length}
          userSpecializations={consultationSpecializations}
          filterName={filterName}
          onFilterName={(event) => handleFilterByName(event, tableId)}
          filterRole={filterRole}
          onFilterRole={(event) => handleFilterByRole(event, tableId)}
          startDate={startDate}
          endDate={endDate}
          title={title}
          handleDateChange={handleDateChange}
          handleExportToCSV={handleExportToCSV}
          openCsv={openCsv}
          setOpenCsv={setOpenCsv}
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <AppointmentListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={mainList.length}
                numSelected={selected.length}
                onRequestSort={(event, property) =>
                  handleRequestSort(event, property, tableId)
                }
                onSelectAllClick={(event) =>
                  handleSelectAllClick(event, tableId, facilityName)
                }
              />
              <TableBody>
                {!!filteredUsers.length &&
                  filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const {
                        id,
                        patientName,
                        doctorName,
                        date,
                        category,
                        consultationId,
                        appointmentDate,
                        patientPhone,
                        price,
                        status,
                      } = row;

                      const selectedUser =
                        !!selected && selected.indexOf(id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id || consultationId}
                          tabIndex={-1}
                          role="checkbox"
                          selected={selectedUser}
                          onClick={(event) => setSelectedFacility(row)}
                        >
                          {/* <TableCell padding="checkbox">
                            <Checkbox
                              checked={selectedUser}
                              // onChange={(event) => handleClick(event, patientName, tableId)}
                              onChange={(event) =>
                                handleClick(event, id, tableId)
                              }
                            />
                          </TableCell> */}

                          <TableCell
                            component="th"
                            scope="row"
                            padding="none"
                            onClick={(event) => handleModalOpen(row)}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              {/* <Avatar alt={patientName} src={logoUrl} /> */}
                              <Typography variant="subtitle2" noWrap>
                                {patientName}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell
                            align="left"
                            onClick={(event) => handleModalOpen(row)}
                          >
                            {doctorName}
                          </TableCell>

                          <TableCell
                            align="left"
                            onClick={(event) => handleModalOpen(row)}
                          >
                            {patientPhone}
                          </TableCell>

                          <TableCell
                            align="left"
                            onClick={(event) => handleModalOpen(row)}
                          >
                            {price || category}
                          </TableCell>
                          <TableCell
                            align="left"
                            onClick={(event) => handleModalOpen(row)}
                          >
                            {/* <span style={{ fontWeight: "600" }}>
                              {dtf.format(new Date(appointmentDate))}
                            </span> */}
                            {appointmentDate || date}
                          </TableCell>

                          <TableCell align="left">
                            <Label
                              color={
                                status === "missed" || status === "rejected"
                                  ? "error"
                                  : status === "doctorMissed" ||
                                    status === "unpaid"
                                  ? "warning"
                                  : status === "incomplete" ||
                                    status === "unaccepted"
                                  ? "info"
                                  : "success"
                              }
                              // color='primary'
                            >
                              {status}
                            </Label>
                          </TableCell>

                          {/* <TableCell align="right">
                            <IconButton
                              size="large"
                              color="inherit"
                              onClick={(event) => {
                                setConsultationId(id);
                                handleOpenMenu(event, tableId);
                              }}
                            >
                              <Iconify
                                icon={"eva:more-vertical-fill"}
                                sx={{
                                  color: "primary.main",
                                }}
                              />
                            </IconButton>
                          </TableCell> */}
                        </TableRow>
                      );
                    })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>

              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          Not found
                        </Typography>

                        <Typography variant="body2">
                          No results found for &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                          <br /> Try checking for typos or using complete words.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          //IMPORTANT // count={mainList.length} //DO NOT REMOVE
          count={filteredUsers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) =>
            handleChangePage(event, newPage, tableId)
          }
          onRowsPerPageChange={(event) =>
            handleChangeRowsPerPage(event, tableId)
          }
        />
      </Box>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={() => handleCloseMenu(tableId)}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        {!!tabsValue && (
          <MenuItem
            onClick={(event) => {
              approvalRequestHandler(consultationId);
              handleCloseMenu(tableId);
            }}
            disabled={isLoading}
          >
            <Iconify icon={"eva:compass-fill"} sx={{ mr: 2 }} />
            Approve
          </MenuItem>
        )}

        <MenuItem sx={{ color: "error.main" }}>
          <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
      <TransitionsModal
        selectedConsultation={selectedConsultation}
        openModal={openModal}
        handleModalOpen={handleModalOpen}
        handleModalClose={handleModalClose}
        approvalRequestHandler={approvalRequestHandler}
        isLoading={isLoading}
      />
    </>
  );
}
