import { Box } from "@mui/material";
import React from "react";
import useContract from "../hooks/useContract";
import CustomTab from "../components/Tab/Tab";
import ContractCard from "../components/ContractCard/ContractCard";
// import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Contract = () => {
  const { tab, setTab } = useContract();
  // const navigate = useNavigate();

  const handleRoutes = () => {
    toast.info("There is no any implementation for this currently, check back in the future", {
      position: "top-center",
      hideProgressBar: true,
      autoClose: 2000,
    });
  };

  return (
    <Box
      sx={{
        marginTop: "1rem",
        padding: "1.5rem",
      }}
    >
      <CustomTab
        setTab={setTab}
        tabNumber={1}
        isSelected={tab === 1}
        text="CONTRACT TYPES"
      />
      <div style={{ display: "flex", columnGap: "4rem", marginTop: "3rem" }}>
        <div
          style={{
            padding: "2rem 0",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            border: "1px dashed #808080 ",
            borderRadius: "12px",
            width: "302px",
            height: "200px",
            cursor: "pointer",
          }}
          // onClick={() => navigate("/addcontract")}
          onClick={handleRoutes}
        >
          <p style={{ fontSize: "30px", color: "#2CBCEF" }}>+</p>
          <p style={{ marginTop: "-10px" }}>Add new</p>
        </div>
        <ContractCard />
      </div>
    </Box>
  );
};

export default Contract;
