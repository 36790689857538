import React, { useEffect, useState } from "react";
import moment from "moment";
import { ArrowLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";
import apiClient from "../../../http/common";
import { useUser } from "../../../hooks/user-hook";
import { PropagateLoader } from "react-spinners";

const NotificationItem = ({ title, description, date }) => {
  return (
    <div className="flex justify-between w-full items-center border-b py-4">
      <div>
        <h2 className="font-semibold text-lg mb-2">{title}</h2>
        <p className="text-gray-500 text-sm">{description}</p>
      </div>
      <div className="flex flex-col items-end">
        <span className="text-gray-400 text-sm">{date}</span>
        <button className="bg-[#2CBCEF] text-white px-4 py-2 rounded-md mt-2">
          View
        </button>
      </div>
    </div>
  );
};

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [pageSize, setPageSize] = useState(4);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  
  const { userDetails } = useUser(); // Fetch user details from custom hook
  console.log(userDetails);

  // Fetch notifications
  useEffect(() => {
    if (userDetails?.id) {
      fetchNotifications();
    }
  }, [userDetails]);

  const fetchNotifications = async () => {
    if (!userDetails?.id) return;

    try {
      const response = await apiClient.get(
        `/notification/notification/GetUser-Notifications?userId=${userDetails?.id}&pageNumber=${pageNumber}&pageSize=${pageSize}`
      );
      setNotifications(response.data);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    } finally {
      setLoading(false);
    }
  };

  function formatDateTime(datetime) {
    const date = moment(datetime);
    return date.format("MMM D, h:mm a"); // e.g., "Oct 11, 2023 at 3:45 pm"
  }

  return (
    <div className="p-6 w-full">
      {/* Back Button */}
      <span
        onClick={() => navigate(-1)}
        className="text-sm cursor-pointer flex gap-2 items-center mb-6"
      >
        <ArrowLeft className="w-4 h-4" />
        <span>Back</span>
      </span>

      {/* Page Title */}
      <h1 className="text-2xl font-semibold mb-6">Notifications</h1>

      {loading ? (
        <div className="flex justify-center items-center">
          {/* Loading spinner can be added here */}
          <PropagateLoader />
        </div>
      ) : (
        <div className="space-y-6">
          {notifications?.data?.map((notification, index) => (
            <NotificationItem
              key={index}
              title={notification.subject}
              description={notification.notificationBody}
              date={formatDateTime(notification.dateTime)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Notifications;
