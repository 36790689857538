import { ArrowLeft } from "lucide-react";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const HealthInsurerDetailsPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  console.log(state);

  return (
    <div className="bg-gray-50 min-h-screen p-8">
      {/* Back button */}
      <span
        onClick={() => navigate(-1)}
        className="text-sm cursor-pointer flex gap-2 items-center mb-6"
      >
        <ArrowLeft className="w-4 h-4" />
        <span>Back</span>
      </span>

      {/* Page Title */}
      <h1 className="text-2xl font-semibold mb-6">Health Insurance</h1>

      {/* Insurer Image using <img> tag */}
      <div className="w-full h-64 lg:h-96 rounded-lg overflow-hidden shadow-lg mb-6">
        {state?.logo ? (
          <img
            src={state?.logo}
            alt="Health Insurer Logo"
            className="w-full h-full object-cover"
          />
        ) : (
          <p className="text-center py-10">No image available</p>
        )}
      </div>

      {/* Details Section */}
      <div className="bg-white rounded-lg p-6 shadow-md flex justify-between items-center">
        <div>
          <p className="text-gray-500 text-sm">Name</p>
          <p className="font-medium text-lg">{state?.name || "N/A"}</p>
        </div>

        <div>
          <p className="text-gray-500 text-sm">Email</p>
          <p className="font-medium text-lg">{state?.email || "N/A"}</p>
        </div>

        <div>
          <p className="text-gray-500 text-sm">Phone Number</p>
          <p className="font-medium text-lg">{state?.phoneNumber || "N/A"}</p>
        </div>
      </div>
    </div>
  );
};

export default HealthInsurerDetailsPage;
