import React, { useState } from "react";

function ToggleSwitch({ checked, onChange, show, showLabel }) {
  const handleSwitchChange = (event) => {
    if (onChange) {
      onChange(event.target.checked);
    }
  };

  const switchStyle = {
    display: "block",
    backgroundColor: checked ? "#2CBCEF" : "#A6AAB4",
    width: "3rem",
    height: "1.6rem",
    borderRadius: "50px",
  };

  const dotStyle = {
    position: "absolute",
    left: checked ? "10px" : "1px",
    top: "0.2rem",
    backgroundColor: "white",
    width: "1.1rem",
    height: "1.2rem",
    borderRadius: "99px",
    transition: checked ? "transform 0.2s ease-in-out" : "none",
    transform: checked ? "translateX(100%)" : "none",
    boxShadow: "-2px 1px 6px 0px #00000040"
    
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <label
        style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
      >
        <div
          style={{
            position: "relative",
            marginTop: 2,
          }}
        >
          <input
            type="checkbox"
            style={{ display: "none" }}
            onChange={handleSwitchChange}
            checked={checked}
          />
          <div style={switchStyle}></div>
          <div style={dotStyle}></div>
        </div>
        {show && (
          <div style={{ marginLeft: "3px", color: "#333", fontWeight: "bold" }}>
            {showLabel ? (checked ? "On" : "Off") : ""}
          </div>
        )}
      </label>
    </div>
  );
}

export default ToggleSwitch;
