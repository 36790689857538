import { useMemo, useState } from "react";

import { useHttpClient } from "../../../hooks/http-hook";
import { applySortFilter } from "./PartnersHelperFunctions";
import { useForm } from "../../../hooks/form-hook";
import { getComparator } from "../../../utils/helper";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  CreatePartner,
  fetchApprovedPartners,
  fetchUnApprovedPartners,
  updateApprovalRequest,
} from "../../../fetch/partner";
import { useNavigate } from "react-router-dom";
import { reactAppSubscriptionKey } from "../../../http/common";
import { toast } from "react-toastify";

function usePartner(auth) {
  const { token } = auth;
  const queryClient = useQueryClient();
  const [success, setSuccess] = useState({});
  const [error, setError] = useState();
  const [openCreatePage, setOpenCreatePage] = useState(false);
  const switchForm = (event) => setOpenCreatePage(true);
  const switchList = (event) => setOpenCreatePage(false);
  const [tabsValue, setTabsValue] = useState(0);

  // const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { isLoading, sendRequest } = useHttpClient();

  const clearError = () => {
    setError(null);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccess((prev) => ({
      value: false,
      message: "",
    }));
    if (event) {
      event.preventDefault();
    }
  };

  const handleTabsValueChange = (event, newValue) => {
    setTabsValue(newValue);
  };

  const {
    data: unApprovedPartners,
    isLoading: unApprovedLoading,
    error: unApprovedError,
    isError: unApprovedIsError,
  } = useQuery({
    queryKey: ["unApprovedPartners"],
    queryFn: ({ signal }) => fetchUnApprovedPartners(token, signal),
  });

  const {
    data: approvedPartners,
    isLoading: approvedLoading,
    error: approvedError,
    isError: approvedIsError,
  } = useQuery({
    queryKey: ["approvedPartners"],
    queryFn: ({ signal }) => fetchApprovedPartners(token, signal),
  });

  const [formState, inputHandler, setFormData] = useForm(
    {
      Email: {
        value: "",
        isValid: false,
      },
      BusinessName: {
        value: "",
        isValid: false,
      },
      PhoneNumber: {
        value: "",
        isValid: false,
      },
      Address: {
        value: "",
        isValid: false,
      },
      Logo: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  const partnerCreationMutation = useMutation(CreatePartner, {
    onSuccess: (data, inputs) => {
      inputs.onSubmitProps.setSubmitting(false);
      setSuccess({
        value: data?.isSuccessful,
        message: data?.message,
      });
      toast.info("Partner is succefully created", {
        position: "top-center",
        hideProgressBar: true,
        autoClose: 2000,
      });
      inputs.onSubmitProps.resetForm();
      switchList();
      setTabsValue(1);
      return queryClient.invalidateQueries(["unApprovedPartners"]);
    },
    onError: (error) => {
      const message = error?.response?.data?.message || error?.message;
      setError(message);
    },
  });

  const partnerCreationHandler = async (values, onSubmitProps) => {
    const formData = new FormData();
    formData.append("Email", values.Email);
    formData.append("BusinessName", values.BusinessName);
    formData.append("PhoneNumber", values.PhoneNumber);
    formData.append("Address", values.Address);
    formData.append("Logo", values.Logo);

    partnerCreationMutation.mutate({
      formData,
      onSubmitProps,
      token,
    });
  };

  const partnerCreationHandlers = async (values, onSubmitProps) => {
    console.log("values", values);

    try {
      const formData = new FormData();
      formData.append("Email", values.Email);
      formData.append("BusinessName", values.BusinessName);
      formData.append("PhoneNumber", values.PhoneNumber);
      formData.append("Address", values.Address);
      formData.append("Logo", values.Logo);

      const responseData = await sendRequest(
        "https://cloudclinic-apim.azure-api.net/staging/admin/api/AdminAPI/CreatePartner",
        "POST",
        formData,
        {
          // 'Content-Type': 'multipart/form-data',
          Authorization: "Bearer " + token,
          "Ocp-Apim-Subscription-Key": reactAppSubscriptionKey,
        }
      );
      // console.log('responseData', responseData)
      onSubmitProps.setSubmitting(false);
      onSubmitProps.resetForm();
      console.log(responseData);
      
      setSuccess({
        value: responseData?.isSuccessful,
        message: responseData?.message,
      });
      // PartnerPageSwitch();
      setTabsValue(1);
    } catch (err) {}
  };

  const ApprovePartnerMutation = useMutation({
    mutationKey: "approvePartnerMutation",
    mutationFn: updateApprovalRequest,
    onSuccess: (approvedPartnerData, inputs) => {
      const responseData = approvedPartnerData?.data;
      handleCloseMenu(inputs?.tableId);
      setTabsValue(0);
      setSuccess({
        value: responseData?.isSuccessful,
        message: responseData?.message,
      });
      return queryClient.invalidateQueries({
        queryKey: ["approvedPartners", "unApprovedPartners"],
      });
    },
    onError: (error) => {
      // console.log('error', error)
      const message = error?.response?.data?.message || error?.message;
      setError(message);
    },
  });

  const approvalPartnerRequestHandler = async (id,facilityId, tableId) => {
    const data = {
      entityId: id,
      partnerId: facilityId,
    };

    ApprovePartnerMutation.mutate({
      data,
      token,
      tableId,
    });
  };

  const [open, setOpen] = useState({
    approved: null,
    unApproved: null,
  });

  const [page, setPage] = useState({
    approved: 0,
    unApproved: 0,
  });

  const [order, setOrder] = useState({
    approved: "asc",
    unApproved: "asc",
  });

  const [selected, setSelected] = useState({
    approved: [],
    unApproved: [],
  });

  const [orderBy, setOrderBy] = useState({
    approved: "businessName",
    unApproved: "businessName",
  });

  const [filterName, setFilterName] = useState({
    approved: "",
    unApproved: "",
  });

  // const [filterRole, setFilterRole] = useState('');

  const [filterRole, setFilterRole] = useState({
    approved: "",
    unApproved: "",
  });

  const [rowsPerPage, setRowsPerPage] = useState({
    approved: 5,
    unApproved: 5,
  });

  const handleOpenMenu = (event, id) => {
    setOpen((prev) => ({
      ...prev,
      [id]: event.currentTarget,
    }));
  };

  const handleCloseMenu = (id) => {
    setOpen((prev) => ({
      ...prev,
      [id]: null,
    }));
  };

  const handleRequestSort = (event, property, id) => {
    const isAsc = orderBy[id] === property && order[id] === "asc";
    setOrder((prev) => ({
      ...prev,
      [id]: isAsc ? "desc" : "asc",
    }));
    setOrderBy((prev) => ({
      ...prev,
      [id]: property,
    }));
  };

  const handleClick = (event, name, id) => {
    const selectedIndex = selected[id].indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected[id], [name]);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected[id].slice(1));
    } else if (selectedIndex === selected[id].length - 1) {
      newSelected = newSelected.concat(selected[id].slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected[id].slice(0, selectedIndex),
        selected[id].slice(selectedIndex + 1)
      );
    }
    setSelected((prev) => ({
      ...prev,
      [id]: newSelected,
    }));
  };

  const handleChangePage = (event, newPage, id) => {
    setPage((prev) => ({
      ...prev,
      [id]: newPage,
    }));
  };

  const handleChangeRowsPerPage = (event, id) => {
    setPage((prev) => ({
      ...prev,
      [id]: 0,
    }));
    setRowsPerPage((prev) => ({
      ...prev,
      [id]: parseInt(event.target.value, 10),
    }));
  };

  const handleFilterByName = (event, id) => {
    setPage((prev) => ({
      ...prev,
      [id]: 0,
    }));
    setFilterName((prev) => ({
      ...prev,
      [id]: event.target.value,
    }));
  };

  const handleFilterByRole = (event, id) => {
    setPage((prev) => ({
      ...prev,
      [id]: 0,
    }));
    setFilterRole((prev) => ({
      ...prev,
      [id]: event.target.value,
    }));
  };

  const emptyRowsApproved =
    page.approved > 0
      ? Math.max(
          0,
          (1 + page.approved) * rowsPerPage.approved - approvedPartners.length
        )
      : 0;
  const emptyRowsUnApproved =
    page.unApproved > 0
      ? Math.max(
          0,
          (1 + page.unApproved) * rowsPerPage.unApproved -
            unApprovedPartners.length
        )
      : 0;

  const filteredApproved = useMemo(
    () =>
      !!approvedPartners &&
      applySortFilter(
        approvedPartners,
        getComparator(order.approved, orderBy.approved),
        filterName.approved,
        filterRole.approved
      ),
    [
      approvedPartners,
      order.approved,
      orderBy.approved,
      filterName.approved,
      filterRole.approved,
    ]
  );

  const filteredUnApproved = useMemo(
    () =>
      !!unApprovedPartners &&
      applySortFilter(
        unApprovedPartners,
        getComparator(order.unApproved, orderBy.unApproved),
        filterName.unApproved,
        filterRole.unApproved
      ),
    [
      unApprovedPartners,
      order.unApproved,
      orderBy.unApproved,
      filterName.unApproved,
      filterRole.unApproved,
    ]
  );

  const approvedIsNotFound = !filteredApproved.length && !!filterName.approved;
  const unApprovedIsNotFound =
    !filteredUnApproved.length && !!filterName.unApproved;

  const handleSelectAllClick = (event, id) => {
    let docList;
    if (id === "approved") {
      docList = approvedPartners;
      // docList = filteredApproved
    } else {
      docList = unApprovedPartners;
      // docList = filteredUnApproved
    }
    if (event.target.checked) {
      const newSelecteds = docList.map((n) => n.businessName);
      setSelected((prev) => ({
        ...prev,
        [id]: newSelecteds,
      }));
      return;
    }
    setSelected({
      approved: [],
      unApproved: [],
    });
  };

  return {
    approvedPartners,
    unApprovedPartners,
    unApprovedLoading,
    unApprovedError,
    unApprovedIsError,
    approvedLoading,
    approvedError,
    approvedIsError,
    isLoading,
    error,
    clearError,
    open,
    page,
    order,
    selected,
    orderBy,
    filterName,
    filterRole,
    rowsPerPage,
    tabsValue,
    handleTabsValueChange,
    handleOpenMenu,
    handleCloseMenu,
    approvedIsNotFound,
    unApprovedIsNotFound,
    handleSelectAllClick,
    emptyRowsApproved,
    emptyRowsUnApproved,
    filteredApproved,
    filteredUnApproved,
    handleFilterByRole,
    handleFilterByName,
    handleChangeRowsPerPage,
    handleChangePage,
    handleClick,
    handleRequestSort,
    partnerCreationHandler,
    formState,
    inputHandler,
    setFormData,
    success,
    handleSnackbarClose,
    openCreatePage,
    switchList,
    switchForm,
    approvalPartnerRequestHandler,
    partnerCreationMutation,
    ApprovePartnerLoading: ApprovePartnerMutation.isLoading,
  };
}

export default usePartner;
