import { Box, Container, TablePagination, Typography } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import BackButton from "../../layouts/backButton";
import ToggleSwitch from "../../components/ToggleSwitch";
import { useState } from "react";
import { useContext } from "react";
import { AuthContext } from "../../context/auth-context";
import useSponsor from "../../sections/@dashboard/sponsor/SponsorHook";
import { useEffect } from "react";
import ErrorModal from "../../components/Modal/ErrorModal";
import TransitionsSnackbar from "../../components/snackBar";
import FilterInput from "../../components/plans/FilterInput";
import usePlans from "../../components/plans/usePlans";
import apiClient, { reactAppSubscriptionKey } from "../../http/common";
import { useHttpClient } from "../../hooks/http-hook";
import { baseUrl } from "../../utils/helper";
import EmptyPlan from "../../components/icons/plansIcon.svg";
import EmptyComponent from "../../components/plans/EmptyComponent";
import { Oval } from "react-loader-spinner";
// import Plans from "../Plans";

const ItemComponent = ({ title, value }) => {
  return (
    <Box
      sx={{
        // display: "flex",
        pb: "1rem",
        // backgroundColor: 'darkolivegreen',
      }}
    >
      <Typography
        sx={{
          width: "9.8rem",
          fontSize: "0.87rem",
          whiteSpace: "nowrap",

          // backgroundColor: 'firebrick',
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          width: "auto",
          align: "left",
          fontWeight: "medium",
          fontSize: "0.87rem",
          // backgroundColor: 'cornsilk',
        }}
      >
        {value}
      </Typography>
    </Box>
  );
};

const SponsorDetailPage = () => {
  const params = useParams();
  const id = params.sponsorId;
  // console.log(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const token = JSON.parse(localStorage.getItem("userData"));
  // console.log(token.token);
  let navigate = useNavigate();
  const auth = useContext(AuthContext);
  const {
    getSponsorDetails,
    sponsorDetail,
    handleSnackbarClose,
    // error,
    // clearError,
    priceSuccess,
    enableSponsorPricing,
    filteredSponsors,
    page,
    rowsPerPage,
    onRowsPerPageChange,
    handleChangeRowsPerPage,
    handleChangePage,
    id: tableId,
  } = useSponsor(auth);
  const {
    handleSearch,
    filter,
    setsponsorId,
    sponsorId,
    formatDate,
    pageNumber,
    pageSize,
    debouncedSearchQuery,
  } = usePlans();
  const [data, setData] = useState();
  const [transactions, setTransaction] = useState([]);
  const [isSponsorLoading, setIsSponsorLoading] = useState(false);

  useEffect(() => {
    getSponsorDetails(id);
    setsponsorId(id);
  }, [id]);

  // useEffect(() => {
  //   apiClient
  //     .get(
  //       `/sponsor/Sponsor/Admin/Plans?sponsorId=${id}&pageNumber=${pageNumber}&pageSize=${pageSize}&searchPlan=${debouncedSearchQuery? debouncedSearchQuery : ""}`
  //     )
  //     .then((response) => {
  //       setData(response);
  //       console.log(response);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);

  useEffect(() => {
    console.log(debouncedSearchQuery);
    console.log(filter);
    const fetchSponsors = async () => {
      setIsSponsorLoading(true);
      try {
        const responseData = await sendRequest(
          `${baseUrl}/sponsor/Sponsor/Admin/Plans?sponsorId=${id}&pageNumber=${null}&pageSize=${null}&searchPlan=${
            debouncedSearchQuery ? debouncedSearchQuery : ""
          }`,
          undefined,
          undefined,
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "Ocp-Apim-Subscription-Key": reactAppSubscriptionKey,
          }
        );
        setData(responseData?.data);
        setIsSponsorLoading(false);
        console.log(responseData.data);
        // setSponsorList(responseData?.data.data);
      } catch (err) {}
    };
    fetchSponsors();
  }, [sendRequest, debouncedSearchQuery, filter]);

  useEffect(() => {
    // console.log(editPlanObj);
    // console.log(arrayAsString);
    if (data) {
      const transaction = data?.data?.data?.data?.map((item) => {
        let coverages = item?.coverages?.map((obj) => obj.coverageName);
        const firstCoverage = coverages?.[0] + ", ...";

        coverages = coverages?.join(", ");
        return {
          ...item,
          startDate: formatDate(item.startDate),
          expiryDate: formatDate(item.expiryDate),
          coverages,
          firstCoverage,
          isChecked: false,
        };
      });
      setTransaction(transaction);
    }
  }, [data]);

  const [isTogglePrice, setIsToggledPrice] = useState(false);

  useEffect(() => {
    console.log(data);

    if (sponsorDetail) {
      setIsToggledPrice(sponsorDetail?.enableCanCreatePriceTier);
    }
  }, [sponsorDetail]);

  const togglePrice = () => {
    const newToggleValue = !isTogglePrice;
    setIsToggledPrice(newToggleValue);
    enableSponsorPricing(id, newToggleValue);
  };

  const DurationOptions = [
    { value: 0, label: "Weekly" },
    { value: 1, label: "Monthly" },
    { value: 2, label: "Annually" },
  ];

  return (
    <>
      <ErrorModal error={error} onClear={clearError} />
      <TransitionsSnackbar
        open={priceSuccess.value}
        message={priceSuccess.message}
        onClose={handleSnackbarClose}
      />
      <Container>
        <Box sx={{ marginY: 4 }}></Box>
        <BackButton onClick={() => navigate(-1)} />
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", sm: "space-between" },
            // padding: '1.6rem',
            mt: "1.5rem",
            flexWrap: "wrap",
            // width: '50%',
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: "100%" },
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                marginY: 3,
              }}
            >
              Sponsors Information
            </Typography>
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: "1.5rem",
                display: "flex",
                flexWrap: { xs: "wrap", sm: "no-wrap" },
                padding: "1.6rem",
                //   mr: "1.0rem",
                mt: "1rem",
                width: "100%",
                // backgroundColor: 'aquamarine'
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  // backgroundColor: 'bisque',
                  mt: { xs: "1.5rem", sm: "0rem" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <ItemComponent
                    title="Name:"
                    value={sponsorDetail?.sponsorName}
                  />
                  <ItemComponent
                    // title="Date of Registeration:"
                    title="Sponsor Type:"
                    value={sponsorDetail?.sponsorType}
                  />
                  <ItemComponent
                    // title={"Phone Number:"}
                    title="Rc Number:"
                    value={sponsorDetail?.rcNumber}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <ItemComponent
                    title={"Email Address:"}
                    value={sponsorDetail?.email}
                  />
                  <ItemComponent
                    // title={"Number of Beneficiaries:"}
                    title="City:"
                    value={sponsorDetail?.city}
                  />
                  <ItemComponent
                    // title={"Address:"}
                    title="State:"
                    value={sponsorDetail?.state}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            marginY: 2,
            width: { md: "50%" },
            alignItems: "center",
          }}
        >
          <Typography sx={{ whiteSpace: "nowrap" }}>
            {isTogglePrice ? "Marked as HMO" : "Mark as HMO"}
          </Typography>
          <ToggleSwitch
            show={true}
            onChange={togglePrice}
            checked={isTogglePrice}
            showLabel={false}
          />
        </Box>
        {!isSponsorLoading ? (
          <div className="mt-5 text">
            <div className="flex items-center justify-between text">
              <div className="font-semibold text">Plan List</div>
              <div className="flex justify-end w-1/2 text">
                <FilterInput
                  handleSearch={handleSearch}
                  value={filter}
                  placeholder={"Search by Plan name"}
                />
              </div>
            </div>
            {data?.data?.length === 0 ? (
              <div className=" h-[50%] my-5 flex justify-center items-center font-[700] text-[24px] text-[#808080] ">
                <EmptyComponent
                  className={"w-60 text-[#2CBCEF]"}
                  svgPicture={EmptyPlan}
                  title={"Plan is unavailable"}
                  caption={"There is no plan attached to this sponsor."}
                ></EmptyComponent>
              </div>
            ) : (
              <>
                <table className="w-full mt-3 text">
                  <thead className="text w-full bg-[#FAFAFA] h-10 border-b">
                    <tr className="text-[#4A4A4A] font-bold">
                      <td className="w-1/4 pl-2 text-start">Plan Name</td>
                      <td className="text-start">Coverage</td>
                      <td className="text-start">Max Amount</td>
                      <td className="text-start">Duration</td>
                      <td classN ame="text-start">
                        Action
                      </td>
                    </tr>
                  </thead>
                  <tbody className="text-start text-[13px] text-gray-600 w-full">
                    {!!data &&
                      data?.data
                        ?.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        ?.map((tab, index) => (
                          <>
                            <tr
                              className={`text ${
                                (index + 1) % 2 ? "bg-white" : "bg-[#FAFAFA]"
                              }`}
                            >
                              <td className="text-[#444448]  pl-5 py-3">
                                {tab?.planName}
                              </td>
                              <td className="text-[#444448]  pl-5 py-3">
                                {tab?.coverages?.length > 1
                                  ? tab?.coverages[0]?.coverageName + " ..."
                                  : tab?.coverages[0]?.coverageName}
                              </td>
                              <td className="text-[#444448]">
                                N {tab?.maxAmountPerIndividual}
                              </td>
                              <td className="text-[#444448]">
                                {tab?.planDuration === 0
                                  ? "Weekly"
                                  : tab?.planDuration === 1
                                  ? "Monthly"
                                  : "Yearly"}
                              </td>
                              <td className="text-[#444448] ">
                                <button
                                  onClick={() =>
                                    navigate(`/plan-detail/${tab?.planId}`, {
                                      state: tab,
                                    })
                                  }
                                  className="text-[#2CBCEF]"
                                >
                                  View
                                </button>
                              </td>
                            </tr>
                            {/* <hr className="border border-[#FAFAFA] my-1 w-full"/> */}
                          </>
                        ))}
                  </tbody>
                </table>
                <div className="w-full bg-white border-t text">
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    //IMPORTANT // count={mainList.length} //DO NOT REMOVE
                    count={data?.data?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(event, newPage) =>
                      handleChangePage(event, newPage, tableId)
                    }
                    onRowsPerPageChange={(event) =>
                      handleChangeRowsPerPage(event, tableId)
                    }
                  />
                </div>
              </>
            )}
          </div>
        ) : (
          <div className="flex justify-center h-[70vh] w-full items-center text">
            <Oval
              visible={true}
              height="100"
              width="100"
              color="#4fa94d"
              ariaLabel="oval-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        )}
        {/* <Box
              sx={{
                display: "flex",
                marginY: 2,
                width: { md: "50%" },
                alignItems: "center",
                }}
                >
                <Typography sx={{ whiteSpace: "nowrap" }}>
                Allow Tariff Setup
                </Typography>
                <ToggleSwitch
                show={true}
                onChange={togglePrice}
                checked={isTogglePrice}
                showLabel={false}
                />
                </Box> */}
      </Container>
    </>
  );
};

export default SponsorDetailPage;
