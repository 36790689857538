import React, { useEffect } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../../../components/shadch/ui/ui/dialog";
import { Button } from "../../../components/shadch/ui/ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../../components/shadch/ui/ui/form";
import { useForm } from "react-hook-form";
import ToggleSwitch from "../../../components/ToggleSwitch";
import { toast } from "react-toastify";
import apiClient from "../../../http/common";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const formSchema = z.object({
  roleName: z.string().min(1, "Role name is required"),
  permissions: z.array(z.string()).nonempty("At least one permission is required"),
});

const RoleModal = ({ isOpen, onClose, isEditMode, role, onSuccess, rolesRepresentation }) => {
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      roleName: "",
      permissions: [],
    },
  });

  useEffect(() => {
    if (isEditMode && role) {
      form.setValue("roleName", role.roleName);
      form.setValue("permissions", role.permissions || []);
    } else {
      form.reset();
    }
  }, [isEditMode, role, form]);

  const onSubmit = async (data) => {
    const endpoint = isEditMode ? `/admin/api/AdminAPI/UpdateRole` : `/admin/api/AdminAPI/AddNewRole`;
    const requestData = isEditMode ? { id: role.id, ...data } : data;

    try {
      const response = await apiClient.post(endpoint, requestData);
      toast.success(response?.data?.message || `${isEditMode ? "Role updated" : "Role created"} successfully`);
      onSuccess(); // Callback to refresh roles or perform any additional actions
      onClose(); // Close modal on success
    } catch (error) {
      toast.error(`Failed to ${isEditMode ? "update" : "create"} role`);
    }
  };

  const handleTogglePermission = (permission) => {
    const permissions = form.getValues("permissions");
    if (permissions.includes(permission)) {
      form.setValue("permissions", permissions.filter((p) => p !== permission));
    } else {
      form.setValue("permissions", [...permissions, permission]);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-2xl max-h-[80vh] overflow-y-auto" hideCloseButton={true}>
        <DialogHeader>
          <DialogTitle>{isEditMode ? "Edit Role" : "Add Role"}</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormField
              control={form.control}
              name="roleName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Role Name</FormLabel>
                  <FormControl>
                    <input
                      {...field}
                      placeholder="Enter role name"
                      className="input-class w-full p-2 border rounded"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* Permissions Heading */}
            <div className="flex justify-between border-b pb-2">
              <h3 className="text-[#2CBCEF] font-bold">Permissions</h3>
              <h3 className="text-[#444448] font-bold pr-14">Actions</h3>
            </div>

            {/* Scrollable permissions list */}
            <div className="max-h-[40vh] overflow-y-auto">
              {Object.keys(rolesRepresentation).map((perm) => (
                <div
                  key={perm}
                  className="flex items-center justify-between py-2 border-b"
                >
                  <div className="text-gray-700 w-3/4">{rolesRepresentation[perm]}</div>
                  <div className="w-1/4 flex justify-end">
                    <ToggleSwitch
                      checked={form.watch("permissions").includes(perm)}
                      onChange={() => handleTogglePermission(perm)}
                    />
                  </div>
                </div>
              ))}
            </div>

            {/* Action buttons */}
            <div className="mt-6 flex justify-end pr-14 space-x-8">
              <Button onClick={onClose} className="border px-8">
                Close
              </Button>
              <Button type="submit" className="bg-[#2CBCEF] px-8 text-white">
                Submit
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default RoleModal;
