// import moneyIcon from "../images/moneyIcon.svg";
// import notificationIcon from "../images/notificationIcon.svg";
// import userIcon from "../images/Settings.svg";
// import walletIcon from "../images/walletIcon.svg";
// import dashboardIcon from "../images/dashboardIcon.svg";
// import logoutIcon from "../images/LogIn.svg";

// export const tabLinks = {
//   sideBarLinks: [
//     {
//       icon: dashboardIcon,
//       label: "DashBoard",
//       path: "home",
//     },
//     {
//       icon: walletIcon,
//       label: "Wallet",
//       path: "wallet",
//     },
//     {
//       icon: moneyIcon,
//       label: "Claims",
//       path: "claims",
//     },
//     {
//       icon: notificationIcon,
//       label: "Notification",
//       path: "notifications",
//     },
//     {
//       icon: userIcon,
//       label: "Account Configuration",
//       path: "userConfigure",
//     },
//   ],
//   buttomLinks: [
//     {
//       icon: logoutIcon,
//       label: "Logout",
//       path: "/",
//     },
//   ],
// };

export const claimStatics = [
  {
    title: "Total Claims Processed ",
    value: "100",
    percentage: "10%",
    text: "From the last week",
  },
  {
    title: "Total Valid Claims ",
    value: "200",
    percentage: "-12%",
    text: "From the last week",
  },
  {
    title: "Total Claims in Review ",
    value: "534",
    percentage: "5%",
    text: "From the last week",
  },
  {
    title: "Total Invalid Claims ",
    value: "67",
    percentage: "-2%",
    text: "From the last week",
  },
];

export const recentTransactions = [
  {
    claim: "Death Claim Payment",
    amount: "N11,000,000.00",
    status: "success",
    date: "12 Jan 2024",
    type: "sent",
  },
  {
    claim: "Death Claim Payment",
    amount: "N11,000,000.00",
    status: "pending",
    date: "12 Jan 2024",
    type: "sent",
  },
  {
    claim: "Top-up",
    amount: "N11,000,000.00",
    status: "failed",
    date: "12 Jan 2024",
    type: "received",
  },
  {
    claim: "Claims Payment",
    amount: "N11,000,000.00",
    status: "failed",
    date: "12 Jan 2024",
    type: "sent",
  },
  {
    claim: "Top-up",
    amount: "N11,000,000.00",
    status: "success",
    date: "12 Jan 2024",
    type: "received",
  },
];

export const description = "A donut chart with text";

export const chartData = [
  { browser: "Death claims", visitors: 150, fill: "#FAB513" },
  { browser: "Surgery claims", visitors: 50, fill: "#2CBCEF" },
];

export const chartConfig = {
  value: {
    label: "Visitors",
  },
  death: {
    label: "Death claims",
    color: "hsl(var(--chart-1))",
  },
  surgery: {
    label: "Surgery claims",
    color: "hsl(var(--chart-2))",
  },
};

export const fileInfo = [
  {
    name: "Williams Jason",
    message: "Please attend to this quickly",
    file: true,
    time: "9:00 PM",
  },
  {
    name: "Williams Jason",
    message: "Please attend to this quickly",
    file: true,
    time: "9:00 PM",
  },
  {
    name: "Williams Jason",
    message: "Please attend to this quickly",
    file: true,
    time: "9:00 PM",
  },
  {
    name: "Williams Jason",
    message: "Please attend to this quickly",
    file: true,
    time: "9:00 PM",
  },
  {
    name: "Williams Jason",
    message: "Please attend to this quickly",
    file: true,
    time: "9:00 PM",
  },
  {
    name: "Williams Jason",
    message: "Please attend to this quickly",
    file: true,
    time: "9:00 PM",
  },
  {
    name: "Williams Jason",
    message: "Please attend to this quickly",
    file: true,
    time: "9:00 PM",
  },
  {
    name: "Williams Jason",
    message: "Please attend to this quickly",
    file: true,
    time: "9:00 PM",
  },
  {
    name: "Williams Jason",
    message: "Please attend to this quickly",
    file: true,
    time: "9:00 PM",
  },
];
export const auditDummyData = [
  {
    text: "You changed the claim status from submitted to in review ",
    time: "9:00 PM",
  },
  {
    text: "Cloud clinic support moved your claim status to valid  ",
    time: "9:00 PM",
  },
  {
    text: "You changed the claim status from submitted to in review ",
    time: "9:00 PM",
  },
  {
    text: "Cloud clinic support moved your claim status to valid  ",
    time: "9:00 PM",
  },
  {
    text: "You changed the claim status from submitted to in review ",
    time: "9:00 PM",
  },
  {
    text: "Cloud clinic support moved your claim status to valid  ",
    time: "9:00 PM",
  },
  {
    text: "You changed the claim status from submitted to in review ",
    time: "9:00 PM",
  },
  {
    text: "Cloud clinic support moved your claim status to valid  ",
    time: "9:00 PM",
  },
  {
    text: "You changed the claim status from submitted to in review ",
    time: "9:00 PM",
  },
];

export const surgeryData = {
  PolicyNumber: "123456789",
  Product: "Eva Gold",
  Title: "Mr",
  PhoneNumber: "090887766552",
  Surname: "Elum",
  FirstName: "Williams",
  MiddleName: "Chinonso",
  EmailAddress: "willy@gmail.com",
};
export const deathDummyData = {
  ben: {
    PolicyNumber: "123456789",
    Product: "Eva Gold",
    Title: "Mr",
    PhoneNumber: "090887766552",
    Surname: "Elum",
    FirstName: "Williams",
    MiddleName: "Chinonso",
    EmailAddress: "willy@gmail.com",
  },
  dec: {
    Surname: "Bola",
    FirstName: "Balogun",
    MiddleName: "Tinubu",
    formalAddress: "12 Ajah Road",
    currentLocation: "12 Ajah Road",
    placeOfDeath: "12 Ajah Road",
    occupationAtDeath: "Politician",
  },
  det: {
    gender: "Male",
    dateOfDeath: "12 Jan 2014",
    cadavarMarks: "Marks on Chest",
    causeOfDeath: "Accident",
    ailment: "Politician",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  },
};
